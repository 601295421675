<template>
  <v-row class="mt-4 mb-4">
    <v-col>
      <h2 class="primary--text">Nouveaux Vouchers</h2>
    </v-col>
    <v-col cols="12" v-if="servicesLoading">
      <carousel>
        <slide v-for="i in 5" :key="i">
          <v-skeleton-loader type="article" class="mr-3" />
        </slide>
      </carousel>
    </v-col>
      <carousel v-else style="width: 100%;"
        :autoplay="true"
        :loop="true"
        :perPageCustom="[
          [100, 1],
          [200, 1],
          [300, 1],
          [500, 2],
          [768, 4],
          [900, 6],
          [1024, 7],
        ]"
        :navigationEnabled="false"
        paginationColor="#d60c56"
      >
        <slide v-for="service in services" :key="service.id">
          <service-item class="px-4"
            :service="service"
            @consumeService="consumeService($event)"
            
          ></service-item>
        </slide>
      </carousel>

    <form-voucher ref="formVoucher" />

    <ServicePrices
      ref="servicePrices"
      @consumeServiceAmount="consumeServiceAmount($event)"
      @orderService="orderService($event)"
    />
  </v-row>
</template>
  
  <script>
import { HTTP } from "@/http-common";
import { Carousel, Slide } from "vue-carousel";
import ServiceItem from "../services/ServiceItem.vue";
import FormVoucher from "../services/FormVoucher.vue";
import ServicePrices from "../services/ServicePrices.vue";
export default {
  components: {
    ServiceItem,
    Carousel,
    Slide,
    FormVoucher,
    ServicePrices,
  },
  data() {
    return {
      servicesLoading: false,
      services: [],
    };
  },

  methods: {
    getServices() {
      this.servicesLoading = true;
      HTTP.get("v1/mobile/services/vouchers")
        .then((res) => {
          this.services = res.data;
          //console.log(this.services);
          this.servicesLoading = false;
        })
        .catch(() => {
          this.servicesLoading = false;
        });
    },

    consumeService(service) {
      if (service.type.name == "Voucher" || service.type.name == "Carte") {
        this.$refs.servicePrices.open(service);
      }
    },

    consumeServiceAmount(event) {
      var amount = event.amount;
      var service = event.service;

      if (service.type.name == "Voucher") {
        this.$refs.formVoucher.openAmount(service, amount);
      }
    },

    orderService(service) {
      this.$refs.formVoucher.openOrder(service);
    },
  },
  mounted() {
    this.getServices();
  },
};
</script>