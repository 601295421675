<template>
  <div>
    <v-dialog v-model="dialog" max-width="500" persistent round>
      <v-card v-if="refill">
        <v-card-title>
          Accepter le transfert
          <v-spacer />
          <v-btn icon class="v-btn--active" color="primary" @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text class="pa-6">
          <v-alert
            dense
            v-if="error"
            color="red"
            dark
            icon="mdi-information-outline"
            border="left"
            prominent
          >
            {{ error }}
          </v-alert>
          <p class="text-center">
            Veuillez saisir le code suivant pour confirmer la transaction.<br/>
            <span class="gifty--text font-weight-medium">{{ refill.sms_code }}</span>
          </p>

          <v-text-field
            outlined
            prepend-inner-icon="mdi-key-outline"
            type="text"
            v-model="form.code"
            label="Code Sms *"
            hide-details=""
            :color="error ? 'red' : 'primary'"
            :class="error ? 'redBorder' : ''"
            @input="error ? (error = '') : false"
          >
          </v-text-field>

          <v-btn
              @click="save"
              depressed
              :loading="btnLoading"
              large
              block
              color="primary"
              class="rounded-lg mt-3 mb-5"
            >
              Confirmer
            </v-btn>

        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { HTTP } from "@/http-common";
export default {
  data() {
    return {
      loading: false,
      dialog: false,
      btnLoading: false,
      form: {
        code: "",
      },
      refill: null,
      error: "",
    };
  },
  methods: {
    open(refill) {
      this.refill = refill;
      this.dialog = true;
    },
    save() {
      this.loading = true;
      this.btnLoading = true;
      this.error = "";
      HTTP.post("refill/confirm/code/" + this.refill.id, this.form)
        .then((res) => {
          this.loading = false;
          this.btnLoading = false;
          this.close();
          this.$successMessage = "Le transfert a été effectué avec succès";
          this.$store.dispatch("fetchAccount");
        })
        .catch((e) => {
          this.loading = false;
          this.btnLoading = false;
          this.error = "Le code est incorrect";
        });
    },
    close() {
      this.dialog = false;
      this.refill_id = null;
      this.error = "";
      this.$router.go(0);
    },
  },
};
</script>