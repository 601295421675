<template>
  <v-card class="rounded-lg shadow mb-3">
    <v-card-text>
      <v-row v-if="transaction.type == 'received_refill'" class="align-center">
        <v-col cols="8">
          <h3 class="mb-0">
            {{
             "Solde reçu"
            }}
          </h3>
          <!-- state = accepted -->
          <h5 class="grey--text mb-1">{{  transaction.created_at }}</h5>
          <h4 class="lighten-3 mb-1 success--text align-center d-flex">
            <v-icon class="success--text  mr-2">mdi-account</v-icon> 
            {{ transaction.refill.sender ? transaction.refill.sender.name : '-'}}</h4>
          <span class="font-weight-medium success--text darken-2"
            >{{ CurrencyFormatting(transaction.amount) }} DZD
          </span>
        </v-col>
        <v-spacer />
        <v-col class="text-center">
          <v-avatar
            :color="'success'"
          >
            <v-icon dark>{{
              transaction.type == "received_refill"
                ? "mdi-arrow-bottom-left"
                : "mdi-arrow-top-right"
            }}</v-icon>
          </v-avatar>
          <br>
          <v-btn v-if="transaction.refill.state == 'pending'" class="success mt-2" small rounded elevation="0" @click="acceptRefill(transaction.refill)">
            Accepter
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-else  class="align-center">
        <v-col cols="8">
          <h3 class="mb-2">
            {{
              "Solde envoyé"
            }}
          </h3>
          <!-- state = accepted -->
          <h5 class="grey--text mb-1">{{  transaction.created_at }}</h5>
          <h4 class="lighten-3 mb-1 pink--text darken-2 align-center d-flex">
            <v-icon class="pink--text darken-2 mr-2">mdi-account</v-icon> 
            {{ transaction.refill.recipient ? transaction.refill.recipient.name : '-'}}</h4>
          <span class="font-weight-medium pink--text darken-2"
            >{{ CurrencyFormatting(transaction.amount) }} DZD
          </span>

          <span v-if="transaction.transaction_fee" class="font-weight-medium pink--text darken-2">
            + Frais: {{ CurrencyFormatting(transaction.transaction_fee.fee_amount) }} DZD
          </span>
          
        </v-col>
        <v-col class="text-right">
          <v-avatar
            :color="'gifty'"
          >
            <v-icon dark>{{
              "mdi-arrow-top-right"
            }}</v-icon>
          </v-avatar>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
  
</template>

<script>

export default {

  props: ["transaction"],
  data() {
    return {};
  },
  methods: {
    acceptRefill(refill){
      this.$emit("acceptRefill", refill);
    }
  }
};
</script>