<template>
  <div>
    <v-container fluid>
      <v-app-bar fixed elevation="0" dense height="70" class="white" style="max-width: 100%;padding-right: 15px;">
            <div style="display: flex; align-items: center">
                <div style="padding: 20px;padding-left: 0;">
                <v-img height="100%"
                  :src="getImageLink('/images/logo.png')"
                  v-if="$store.state.user.user.image"
                /></div>
              <div class="d-none d-md-inline">
                Bienvenue <br /><b>{{ $store.state.user.user.name }}</b>
              </div>
            </div>

            <v-spacer></v-spacer>
            
            <v-btn icon @click="openQrCode()">
              <v-icon>mdi-qrcode-scan</v-icon>
            </v-btn>
            <v-btn text color="grey" rounded class="mr-2" v-if="$store.getters.getUser">
              {{ CurrencyFormatting($store.getters.getUser.user.account.all_balance) }}
              DZD
            </v-btn>

            <v-menu
              offset-y
              transition="scale-transition"
              min-width="300"
              max-width="300"
              class="mr-2"
              style="margin-right: 15px;"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon class="text-none">
                  <v-badge
                    bordered
                    bottom
                    color="success"
                    dot
                    offset-x="10"
                    offset-y="10"
                  >
                    <v-avatar size="40" >
                      <v-img
                        :src="getImageLink($store.state.user.user.image)"
                        v-if="$store.state.user.user.image"
                      />
                      <v-img
                      class=""
                        v-else
                        src="https://demos.themeselection.com/materio-vuetify-vuejs-admin-template/demo-1/assets/avatar-3.3ef9169b.png"
                      ></v-img>
                    </v-avatar>
                  </v-badge>
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <v-list-item two-line>
                    <v-list-item-avatar size="50">
                      <v-img
                        :src="getImageLink($store.state.user.user.image)"
                        v-if="$store.state.user.user.image"
                      />
                      <v-img
                        v-else
                        src="https://demos.themeselection.com/materio-vuetify-vuejs-admin-template/demo-1/assets/avatar-3.3ef9169b.png"
                      ></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        $store.state.user.user.name
                      }}</v-list-item-title>
                      <v-list-item-subtitle>{{
                        $store.state.user.user.email
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card-title>

                <v-divider />

                <v-card-text class="pa-0">
                  <v-list>
                    <v-list-item @click="selected = 5" exact>
                      <v-list-item-avatar>
                        <v-icon>mdi-account-circle-outline</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-title>Mon compte</v-list-item-title>
                    </v-list-item>

                    <v-list-item to="/gifty/salepoint" exact v-if="userType == 'salepoint'">
                      <v-list-item-avatar>
                        <v-icon>mdi-store</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-title>Mon Point de vente</v-list-item-title>
                    </v-list-item>

                    <v-list-item @click="logout">
                      <v-list-item-avatar>
                        <v-icon>mdi-logout</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-title>Déconnexion</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-menu>
          </v-app-bar>
      <div class="main" style="margin-top: 100px;">
        <v-row>
          
        </v-row>
        <div v-if="selected == 1">
          <v-row>
            <v-col cols="12" md="6">
              <Balance />
            </v-col>
            <v-col cols="12" md="6">
              <Salepoint />
            </v-col>
          </v-row>

          <Publicities />

          <v-row class="justify-center">
            <v-col cols="12">
              <VouchersCaroussel />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <CardsCaroussel />
            </v-col>
          </v-row>
        </div>

        <LastTransactions v-if="selected == 1" />
        <history v-if="selected == 2" />
        <Services v-if="selected == 3" />
        <Profile v-if="selected == 5" />
        <Notifications v-if="selected == 4" />
      </div>
    </v-container>

    <CurvedBottomNavigation
      :options="options"
      v-model="selected"
      foregroundColor="#d60c56"
      style="width: 100%"
      class="d-flex"
    />

    <QrCode ref="qrcode" />

    <FullscreenPubs />
  </div>
</template>

<script>
import Balance from "./components/Balance.vue";
import Salepoint from "./components/Salepoint.vue";
import LastTransactions from "./LastTransactions.vue";
import History from "./History.vue";
import Services from "./services";
import Profile from "./profile";
import Notifications from "./Notifications";
import Publicities from "./components/Publicities.vue";

import CardsCaroussel from "./components/CardsCaroussel.vue";
import VouchersCaroussel from "./components/VouchersCaroussel.vue";

import { CurvedBottomNavigation } from "bottom-navigation-vue";
import { HTTP } from "@/http-common";
import QrCode from "./components/QrCode.vue";
import FullscreenPubs from "./publicity/Fullscreen.vue";
export default {
  components: {
    VouchersCaroussel,
    CardsCaroussel,
    Balance,
    LastTransactions,
    History,
    Services,
    Profile,
    CurvedBottomNavigation,
    Salepoint,
    Notifications,
    QrCode,
    Publicities,
    FullscreenPubs
  },

  data: () => ({
    selected: 1,
    logoutLoading: false,
    options: [
      {
        id: 1,
        icon: "mdi mdi-home",
        title: "Home",
      },
      {
        id: 2,
        icon: "mdi mdi-history",
        title: "Historique",
      },
      {
        id: 3,
        icon: "mdi mdi-cart",
        title: "Services",
      },
      {
        id: 5,
        icon: "mdi mdi-account",
        title: "Compte",
      },
      {
        id: 4,
        icon: "mdi mdi-bell",
        title: "Notifications",
      },
    ],
  }),
  methods: {
    logout() {
      this.logoutLoading = true;
      HTTP.post("auth/logout")
        .then(() => {
          this.$store.dispatch("user", "");
          this.$store.dispatch("isLoggedIn", false);
          this.$store.dispatch("stores", []);
          this.$router.go(0);
          this.logoutLoading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },

    openQrCode() {
      this.$refs.qrcode.open();
    },
  },
  mounted() {
    if (
      (!this.$store.getters.getUser.user.phone_confirmed || 
      this.$store.getters.getUser.user.phone_confirmed == 0)
      && this.$store.getters.getUser.otp_enabled == true
      ) {
      this.$router.push("/otp");
    }
    
    history.pushState(null, null, location.href);
    window.onpopstate = function () {
      history.go(1);
    };
    
  },

  computed: {
    userType(){
      return this.$store.getters.getUser.user.type;
    }
  }
};

// window.history.pushState('popupclosed', null, null);    // initial state: closed

// var hideModal = function(event) {
//     if (event.state == 'popupclosed') {
//         closepopup();
//     }
// };

// var showModal = function(event) {
//     if (history.state !== 'opened') {
//         window.history.pushState('opened', null, null);
//     }
//     window.addEventListener('popstate', hideModal, { once: true });
</script>