<template>
  <v-dialog v-model="dialog" max-width="700" persistent round>
    <v-card v-if="service">
      <v-card-title>
        {{ service.name }}
        <v-spacer />
        <v-btn icon class="v-btn--active" color="primary" @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text class="pa-6" style="background-color: #ededed !important;">
        <v-row>
          <v-col cols="12" v-if="service.with_order" class="text-center">
            <v-btn elevation="0" large class="rounded-lg primary" @click="orderService()"> 
              <v-icon class="mr-2">mdi-basket-plus-outline</v-icon>
              Commander un voucher
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="pricesLoading">
          <v-col cols="6" md="4" lg="3" v-for="index in 8" :key="index">
            <v-skeleton-loader type="article" />
          </v-col>
        </v-row>
        <v-row v-else class="justify-center">
          <v-col cols="6" md="4" lg="3" v-for="price in prices" :key="price" >
            <v-card v-if="price"
              class="rounded-lg flex flex-column text-center"
              flat
              style="cursor: pointer"
              @click="consumeServicePrice(price)"
            >
              <v-card-text
                :style="'background-color: ' + service.background_color + ';cursor: pointer; height: 170px;'"
                class="text-center justify-center"
              >
                <img src="../../../assets/cloud.png" width="60%" />

                <p class="text-center font-weight-bold mt-5 pt-5 mb-5" :style="'color:'+service.text_color+' '">
                  {{ CurrencyFormatting(price) }} DZD
                </p>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="6" md="4" lg="3" v-if="service.with_build_vouchers">
            <v-card
              class="rounded-lg flex flex-column text-center"
              flat
              style="cursor: pointer; height: 100%;"
              @click="consumeServicePrice(null)"
            >
              <v-card-text
              :style="'background-color: #d60c56; height: 100%;'"
                class="text-center justify-center"
              >
                <img src="../../../assets/cloud.png" width="60%" />

                <p class="text-center white--text font-weight-bold mt-5 mb-5">
                  Montant Personnalisé
                </p>
              </v-card-text>
            </v-card>
          </v-col>

          

        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
  
<script>
import { HTTP } from "@/http-common";

export default {
  components: {},
  data() {
    return {
      prices: [],
      pricesLoading: false,
      dialog: false,
      service: null
    };
  },
  methods: {
    open(service) {
      this.service = service;
      this.getPrices();
      this.dialog = true;
    },
    close() {
      this.service = null;
      this.dialog = false;
    },

    getPrices() {
      this.pricesLoading = true;
      var url = null;

      if(this.service.type.name == "Voucher"){
        url = "v1/mobile/services/vouchers/";
      }
      if(this.service.type.name == "Carte"){
         url = "v1/mobile/cards/prices/";
      }
      HTTP.get(url + this.service.id)
        .then((res) => {
          this.pricesLoading = false;

          var data = [];
          res.data.map((item) => {
            
            if(item.split("-").length > 1){
              data.push(item.split("-")[0]);
            }else{
              data.push( item);
            }
          });


          this.prices = data;
        })
        .catch((err) => {
          this.pricesLoading = false;
          console.log(err);
        });
    },

    consumeServicePrice(price) {
      this.$emit('consumeServiceAmount', {
        amount: price,
        service: this.service
      });
      this.close();
    },

    orderService(){
      this.$emit("orderService", this.service);
    }
  },
  mounted() {
  },
};
</script>