<template>
  <v-row class="mt-4">
    <v-col cols="12" v-if="publicitiesLoading">
      <carousel>
        <slide v-for="i in 5" :key="i">
          <v-skeleton-loader type="article" class="mr-3" />
        </slide>
      </carousel>
    </v-col>

    <carousel
      v-else
      style="width: 100%"
      :autoplay="true"
      :perPageCustom="[
        [100, 1],
        [200, 1],
        [300, 1],
        [500, 1],
        [768, 2],
        [900, 2],
        [1024, 2],
      ]"
      :navigationEnabled="false"
      :loop="true"
      :minSwipeDistance="15"
      paginationColor="#fff"
      :paginationSize="0"
    >
      <slide v-for="publicity in publicities" :key="publicity.id">
        <v-card
          class="rounded mb-3 mx-3"
          style="cursor: pointer; min-width: 90%"
          @click="openPubliciy(publicity)"
        >
          <v-img :aspect-ratio="16 / 9" :src="getImageLink(publicity.image)" />
        </v-card>
      </slide>
    </carousel>
    <FormCard ref="formCard" />
  </v-row>
</template>
  
  <script>
import { HTTP } from "@/http-common";
import { Carousel, Slide } from "vue-carousel";

import FormCard from "../services/FormCard.vue";

export default {
  components: {
    Carousel,
    Slide,
    FormCard,
  },
  data() {
    return {
      publicitiesLoading: false,
      publicities: [],
    };
  },

  methods: {
    getPublicities() {
      this.publicitiesLoading = true;
      HTTP.get("v1/mobile/publicities")
        .then((res) => {
          this.publicities = res.data.data;

          res.data.data.forEach(pub => {
            this.track(pub, "see");
          });
          //console.log(this.publicities);
          this.publicitiesLoading = false;
        })
        .catch(() => {
          this.publicitiesLoading = false;
        });
    },
    openPubliciy(pub) {
      this.track(pub, "click");
      window.open(pub.action, "_blank");
    },
    track(pub, action) {
      HTTP.post("/v1/mobile/publicities/track/" + pub.id, {
        action: action,
      }).then(() => {})
        .catch(() => {});
    },
  },
  mounted() {
    this.getPublicities();
  },
};
</script>