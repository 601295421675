var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticClass:"mt-4 mb-4"},[_c('v-col',[_c('h2',{staticClass:"primary--text"},[_vm._v("Nouveaux Vouchers")])]),(_vm.servicesLoading)?_c('v-col',{attrs:{"cols":"12"}},[_c('carousel',_vm._l((5),function(i){return _c('slide',{key:i},[_c('v-skeleton-loader',{staticClass:"mr-3",attrs:{"type":"article"}})],1)}),1)],1):_c('carousel',{staticStyle:{"width":"100%"},attrs:{"autoplay":true,"loop":true,"perPageCustom":[
        [100, 1],
        [200, 1],
        [300, 1],
        [500, 2],
        [768, 4],
        [900, 6],
        [1024, 7],
      ],"navigationEnabled":false,"paginationColor":"#d60c56"}},_vm._l((_vm.services),function(service){return _c('slide',{key:service.id},[_c('service-item',{staticClass:"px-4",attrs:{"service":service},on:{"consumeService":function($event){return _vm.consumeService($event)}}})],1)}),1),_c('form-voucher',{ref:"formVoucher"}),_c('ServicePrices',{ref:"servicePrices",on:{"consumeServiceAmount":function($event){return _vm.consumeServiceAmount($event)},"orderService":function($event){return _vm.orderService($event)}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }