<template>
  <v-card class="rounded-lg shadow mb-3">
    <v-card-text>
      <v-row  class="align-center">
        <v-col>
          <h3 class="mb-2">
            {{
              "Flexy"
            }}
          </h3>
          <!-- state = accepted -->
          <h5 class="grey--text mb-1">{{  transaction.created_at }}</h5>
          <h4 class="lighten-3 mb-1 pink--text darken-2 align-center d-flex">
            <v-icon class="pink--text darken-2 mr-2">mdi-phone</v-icon> 
            {{ transaction.flexy ? transaction.flexy.phone  : '-'}}</h4>
          <span class="font-weight-medium pink--text darken-2"
            >{{ CurrencyFormatting(transaction.amount) }} DZD
          </span>

          <br>
          <span class="font-weight-medium mt-2 primary--text darken-2">
            Payé: {{ CurrencyFormatting(transaction.total_amount) }} Pts
          </span>

        </v-col>
        <v-spacer/>
        <v-col class="text-right">
          <v-avatar
            :color="'gifty'"
          >
            <v-icon dark>{{
              "mdi-cellphone-wireless"
            }}</v-icon>
          </v-avatar>

          <br />

          <v-chip
            v-if="
              transaction.flexy
            "
            small
            class="ma-2"
            :color="
              $martship_states.colors[
                transaction.flexy.martship_state
              ]
            "
            text-color="white"
          >
            {{
              $martship_states.texts[
                transaction.flexy.martship_state
              ]
            }}
          </v-chip>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: ["transaction"],
  data() {
    return {};
  },
};
</script>