

<template>
  <v-dialog v-model="dialog" max-width="600" persistent round>
    <v-card v-if="dialog">
      <v-card-title>
        QrCode
        <v-spacer />
        <v-btn icon class="v-btn--active" color="primary" @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text
        class="pa-6 text-center flex justify-center"
        style="background-color: #dfdfdf"
      >
        <v-alert
          dense
          v-if="error"
          color="red"
          dark
          icon="mdi-information-outline"
          border="left"
          prominent
        >
          {{ error }}
        </v-alert>

        <v-row v-if="choice == null">
          <v-col cols="12" lg="6">
            <v-card
              color="gifty"
              flat
              rounded
              @click="setChoice('myqrcode')"
              style="height: 100%"
            >
              <v-card-text class="text-center py-5">
                <v-icon class="white--text" size="70">mdi-account</v-icon>
                <h4 class="white--text mt-3">Mon QrCode</h4>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" lg="6">
            <v-card
              color="gifty"
              flat
              rounded
              @click="setChoice('scan')"
              style="height: 100%"
            >
              <v-card-text class="text-center py-5">
                <v-icon class="white--text" size="70">mdi-qrcode-scan</v-icon>
                <h4 class="white--text mt-3">Scanner un voucher</h4>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <div v-else>
          <div v-if="choice == 'myqrcode'">
            <h4 class="mt-3 mb-3 text-center">Mon QrCode</h4>
            <div
              style="
                margin: auto;
                width: 220px;
                padding: 10px;
                background-color: #fff;
              "
            >
              <VueQRCodeComponent :text="code" color="#d60c56" :size="200" />
            </div>
          </div>

          <div v-if="choice == 'scan'">
            <div v-if="scanLoading" class="text-center">
              <v-progress-circular
                :width="3"
                color="primary"
                indeterminate
              ></v-progress-circular>
              <p class="mt-4 primary--text">Vérification ...</p>
            </div>
            <div v-else>
              <div v-if="voucher">
                <v-card flat class="rounded-lg">
                  <v-card-text>
                    <v-simple-table>
                      <template v-slot:default>
                        <tbody>
                          <tr>
                            <td class="font-weight-bold">Montant</td>
                            <td
                              class="
                                font-weight-bold
                                text-center
                                pink--text
                                darken-2
                                text-no-wrap
                              "
                            >
                              {{ CurrencyFormatting(voucher.amount) }} DZD
                            </td>
                          </tr>

                          <tr>
                            <td class="font-weight-bold">Service</td>
                            <td class="text-center text-no-wrap">
                              {{ voucher.service.name }}
                            </td>
                          </tr>

                          <tr>
                            <td class="font-weight-bold">Supplier</td>
                            <td class="text-center text-no-wrap">
                              {{ voucher.supplier.name }}
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card-text>
                </v-card>

                <v-btn
                  @click="consumeVoucher"
                  depressed
                  :loading="btnLoading"
                  large
                  block
                  color="primary"
                  class="rounded-lg mt-3 mb-5"
                >
                  Consommer
                </v-btn>
              </div>

              <div v-else>
                <qrcode-stream @decode="onDecode" class="mb-3" />
                <QrcodeDropZone @decode="onDecode" class="mb-3" />
                <QrcodeCapture @decode="onDecode" class="mb-3" />
              </div>
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
        
  <script>
import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from "vue-qrcode-reader";

import VueQRCodeComponent from "vue-qrcode-component";
import { HTTP } from "@/http-common";
export default {
  components: {
    VueQRCodeComponent,
    QrcodeStream,
    QrcodeDropZone,
    QrcodeCapture,
  },
  data() {
    return {
      choice: null,
      dialog: false,
      code: "" + this.$store.state.user.user.account.id,
      scanLoading: false,
      error: null,
      voucher: null,
      btnLoading: false,
    };
  },
  methods: {
    open() {
      this.dialog = true;
      this;
    },
    close() {
      this.dialog = false;
      this.choice = null;
      this.scanLoading = false;
      this.error= null;
      this.voucher = null;
      this.btnLoading = false;
    },
    setChoice(value) {
      this.choice = value;
    },
    onDecode(value) {
      this.scanLoading = true;

      HTTP.post("v1/mobile/services/vouchers/check_code", {
        code: value,
      })
        .then((response) => {
          this.scanLoading = false;
          this.voucher = response.data.data;
        })
        .catch((err) => {
          this.error = "Code Voucher invalide.";
          this.scanLoading = false;
        });
    },
    consumeVoucher() {
      this.btnLoading = true;

      HTTP.post("v1/mobile/services/vouchers/consume_code", {
        code: this.voucher.code,
      })
        .then(() => {
          this.btnLoading = false;

          this.$successMessage = "opération terminée avec succès";
          this.$store.dispatch("fetchAccount");
          this.close();
        })
        .catch((err) => {
          this.error = "Code Voucher invalide.";
          this.btnLoading = false;
        });
    },
  },
  computed: {},
};
</script>