<template>
  <div class="mt-4">
    <v-skeleton-loader v-if="categoriesLoading" type="article" height="50" class="text-center"/>

    <div v-else class="d-flex justify-start" style="overflow:auto">
      <!-- <v-btn
        elevation="0"
        rounded
        :color="selectedCategoryId == null ? 'primary' : 'white'"
        class="mb-2 mr-2 py-5 text-none"
        @click="select(null)"
        
      >
        {{ "Tous" }}
      </v-btn> -->

      <v-btn
        elevation="0"
        rounded
        v-for="category in categories"
        :color="selectedCategoryId == category.id ? 'primary' : 'white'"
        class="mb-2 mr-2 py-5 text-none"
        :key="category.id"
        @click="select(category)"
        style="padding-left: 5px"
      >
        <v-avatar small style="width: 20px; margin-left: 0; padding: 0">
          <v-img
            :src="getImageLink(category.image)"
            max-width="30"
            max-height="30"
          />
        </v-avatar>
        {{ category.name }}
      </v-btn>
    </div>
  </div>
</template>

  <script>
import { HTTP } from "@/http-common";

export default {
  data() {
    return {
      selectedCategoryId: null,
      selectedCategory: null,
      categories: [],
      categoriesLoading: false,
    };
  },
  methods: {
    select(category) {
      if (this.selectedCategory == category) {
        this.selectedCategory = null;
        this.selectedCategoryId = null;
        this.$emit("setCategory", null)
      } else {
        this.selectedCategory = category;
        if(category != null){
          this.selectedCategoryId = category.id;
        }else{
          this.selectedCategoryId = null
        }
        this.$emit("setCategory", category)
      }
      
    },
    getCategories() {
      this.categoriesLoading = true;

      HTTP.get("/v1/mobile/categories")
        .then((res) => {
          this.categories = res.data.data;
          this.categoriesLoading = false;
        })
        .catch((e) => {
          console.log(e);
          this.categoriesLoading = false;
        });
    },
  },
  mounted() {
    this.getCategories();
  },
};
</script>

<style scoped>
/* custom scrollbar */
::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}
</style>