<template>
  <v-card class="rounded-lg shadow mb-3">
    <!-- <v-card-text>
      <v-row>
        <v-col>
          <h3 class="mb-2">Carte {{ transaction.type }}</h3>
          <span class="font-weight-medium"
            >{{ CurrencyFormatting(transaction.amount) }}
          </span>
        </v-col>
        <v-col class="text-right">
          <v-avatar color="blue">
            <v-icon dark>mdi-credit-card</v-icon>
          </v-avatar>
        </v-col>
      </v-row>
    </v-card-text> -->

    <v-card-text>
      <v-row class="align-center">
        <v-col>
          <h3 class="mb-2">Carte {{ transaction.type }}</h3>
          <!-- state = accepted -->
          <h5 class="grey--text mb-1">{{ transaction.created_at }}</h5>
          <h4 class="lighten-3 mb-1 blue--text darken-2 align-center d-flex">
            <v-icon class="blue--text darken-2 mr-2"
              >mdi-credit-card-check</v-icon
            >
            {{
              transaction.idoom_card
                ? transaction.idoom_card.card.card_number
                : "-"
            }}
          </h4>
          <span class="font-weight-medium blue--text darken-2"
            >{{ CurrencyFormatting(transaction.amount) }} DZD
          </span>

          <br>
          <span class="font-weight-medium mt-2 primary--text darken-2">
            Payé: {{ CurrencyFormatting(transaction.total_amount) }} Pts
          </span>

        </v-col>
        <v-spacer />
        <v-col class="text-center">
          <v-avatar :color="'blue'">
            <v-icon dark>{{ "mdi-credit-card" }}</v-icon>
          </v-avatar>
          <br />

          <v-chip
            v-if="
              transaction.idoom_card &&
              transaction.idoom_card.card &&
              transaction.idoom_card.card.martship_card
            "
            small
            class="ma-2"
            :color="
              $martship_states.colors[
                transaction.idoom_card.card.martship_card.martship_state
              ]
            "
            text-color="white"
          >
            {{
              $martship_states.texts[
                transaction.idoom_card.card.martship_card.martship_state
              ]
            }}
          </v-chip>
          <v-chip v-else small class="ma-2" color="success" text-color="white">
            Acceptée
          </v-chip>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: ["transaction"],
  data() {
    return {};
  },
};
</script>