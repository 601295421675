<template>
  <div>
    <v-dialog v-model="dialog" max-width="800" persistent round>
      <v-card v-if="service">
        <v-card-title>
          {{ service.name }}
          <v-spacer />
          <v-btn icon class="v-btn--active" color="primary" @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />

        <v-card-text v-if="loading">
          <v-skeleton-loader type="article" />
        </v-card-text>

        <v-card-text class="pa-6" v-else>
          <v-alert
            dense
            v-if="promo_code_error"
            color="red"
            dark
            icon="mdi-information-outline"
            border="left"
            prominent
          >
            Code promo invalid
          </v-alert>

          <v-row>
            <v-col cols="12" md="12">
              <v-simple-table class="mb-4">
                <template v-slot:default>
                  <tbody>
                    <tr v-if="service.promotion_user">
                      <td class="font-weight-bold">Promotion Service (.. %)</td>
                      <td
                        class="font-weight-bold pink--text text-right lighten-4 text-right text-no-wrap"
                      >
                        <v-btn class="rounded-lg blue--text" elevation="0">
                          {{
                            service.promotion_user
                              ? service.promotion_user.discount
                              : 0
                          }}
                          %
                        </v-btn>
                      </td>
                    </tr>

                    <tr v-if="service.promotion_user">
                      <td class="font-weight-bold">Promotion service (DZ)</td>
                      <td
                        class="font-weight-bold pink--text text-right lighten-4 text-right text-no-wrap"
                      >
                        {{ CurrencyFormatting(getPromotionService) }} DZ
                      </td>
                    </tr>

                    <tr v-if="with_code">
                      <td class="font-weight-bold">Promotion code (DZ)</td>
                      <td
                        class="font-weight-bold pink--text text-right lighten-4 text-right text-no-wrap"
                      >
                        {{ CurrencyFormatting(promo_code_amount) }} DZ
                      </td>
                    </tr>

                    <tr>
                      <td class="font-weight-bold text-center" colspan="2">
                        Promotion Service
                      </td>
                    </tr>

                    <tr v-if="!selectPromotion()">
                      <td class="font-weight-medium text-center" colspan="2">
                        Aucune Promotion
                      </td>
                    </tr>

                    <tr v-if="selectPromotion()">
                      <td class="font-weight-bold">
                        {{ selectPromotion().name }}
                      </td>
                      <td
                        class="font-weight-bold pink--text text-right lighten-4 text-right text-no-wrap"
                      >
                        <v-btn class="rounded-lg blue--text" elevation="0">
                          {{ selectPromotion().discount }} %
                        </v-btn>

                        <v-btn class="rounded-lg success--text" elevation="0">
                          {{ CurrencyFormatting(promotionService()) }} DZ
                        </v-btn>
                      </td>
                    </tr>

                    <tr>
                      <td class="font-weight-bold">Montant</td>
                      <td
                        class="font-weight-bold pink--text text-right lighten-4 text-right text-no-wrap"
                      >
                        <span
                          :class="promotionService() > 0 ? 'line-through' : ''"
                        >
                          {{ CurrencyFormatting(form.amount) }} DZ
                        </span>
                        <span v-if="promotionService() > 0">
                          {{
                            CurrencyFormatting(
                              form.amount -
                                promotionService() -
                                promo_code_amount
                            )
                          }}
                          DZ
                        </span>
                      </td>
                    </tr>

                    <!-- <tr>
                  <td class="font-weight-bold">Free Service (.. %)</td>
                  <td
                    class="font-weight-bold pink--text text-right lighten-4 text-right text-no-wrap"
                  >
                    <v-btn class="rounded-lg blue--text" elevation="0">
                      {{ service.fee_user ? service.fee_user.value : 0 }} %
                    </v-btn>
                  </td>
                </tr> -->

                    <tr>
                      <td class="font-weight-bold text-center" colspan="2">
                        Frais Service
                      </td>
                    </tr>

                    <tr
                      v-if="service.fees_user && service.fees_user.length == 0"
                    >
                      <td class="font-weight-medium text-center" colspan="2">
                        Aucun Frais
                      </td>
                    </tr>

                    <template v-for="fee in service.fees_user" v-else>
                      <tr :key="fee.id">
                        <td class="font-weight-bold">{{ fee.name }}</td>
                        <td
                          class="font-weight-bold pink--text text-right lighten-4 text-right text-no-wrap"
                        >
                          <v-btn class="rounded-lg blue--text" elevation="0">
                            {{ fee.value }} %
                          </v-btn>
                          =
                          {{ CurrencyFormatting(getFeeServiceFor(fee)) }} DZ
                        </td>
                      </tr>
                    </template>

                    <tr>
                      <td class="font-weight-bold">
                        <h2>Total</h2>
                      </td>
                      <td
                        class="font-weight-bold pink--text text-right lighten-4 text-right text-no-wrap h3"
                      >
                        <h2>{{ CurrencyFormatting(getTotalAmount) }} DA</h2>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>

              
                <h2 class="font-weight-bold mb-4 text-center">
                  Somme finale:
                  <span class="font-weight-bold success--text"
                    >{{ CurrencyFormatting(getTotalAmount) }} DA</span
                  >
                </h2>
                <div class="d-flex justify-center">
                <v-radio-group
                  v-model="payment_mode"
                  row
                  hide-details=""
                  class="mt-0"
                >
                  <v-radio :key="'gifty'" :label="'Gifty'" :value="'gifty'">
                    <template v-slot:label>
                      <img
                        src="../../../assets/logo2.png"
                        style="width: 80px"
                      />
                    </template>
                  </v-radio>

                  <v-radio :key="'cib'" :label="'CIB'" :value="'cib'">
                    <template v-slot:label>
                      <img
                        src="../../../assets/carte_CIB.jpg"
                        style="width: 80px"
                      />
                    </template>
                  </v-radio>
                </v-radio-group>

                <div class="d-flex mt-4"></div>
              </div>

              <v-btn
                :disabled="!payment_mode"
                @click="confirm"
                depressed
                large
                block
                color="primary"
                class="rounded-lg mt-4 mb-5"
              >
                Enregister
              </v-btn>
            </v-col>
          </v-row>

          <!-- <v-row>
            <v-divider />
            <v-col cols="12" class="justify-center text-center mt-5">
              <img
                src="../../../assets/green_number.png"
                style="width: 200px; margin: auto"
              />
              <p class="text-center font-weight-bold">Numéro SATIM</p>
            </v-col>
          </v-row> -->
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { HTTP } from "@/http-common";
import { nextTick } from "vue";
export default {
  components: {},
  data() {
    return {
      form: null,
      service: null,
      dialog: false,
      loading: false,
      with_code: false,
      promo_code_amount: 0,
      promo_code_error: false,
      promotion_code: null,
      payment_mode: "gifty",
      acceptTerms: false,

      recaptcha_verified: false,
      recaptcha_token: null,
    };
  },
  methods: {
    recaptchaVeridied(response) {
      this.recaptcha_verified = true;

      this.recaptcha_token = response;
    },
    open(form, service, with_code) {
      this.service = null;
      this.with_code = false;

      this.promo_code_error = "";
      this.promotion_code = "";
      this.dialog = true;

      this.form = form;
      this.service = service;
      this.with_code = with_code;
      this.dialog = true;
      this.promo_code_error = "";
      if (with_code) {
        this.checkCode(form.promo_code, service.id, form.amount);
      }
      this.payment_mode = null;

      this.acceptTerms = false;
      this.recaptcha_token = "";
      this.recaptcha_verified = false;

      nextTick(() => {
        this.$refs.recaptcha.execute();
        this.$refs.recaptcha.reset();
      });
    },

    close() {
      this.promo_code_error = "";
      this.promotion_code = "";
      this.with_code = false;
      this.dialog = false;
    },

    getFeeServiceFor(fee_user) {
      var result = 0;

      var amount = this.form.amount;

      if (!fee_user) {
        return result;
      }

      if (amount > fee_user.max_amount) {
        result = (fee_user.max_amount * fee_user.value) / 100;
      } else {
        result = (amount * fee_user.value) / 100;
      }

      if (result < fee_user.basic_amount) {
        result = fee_user.basic_amount;
      }
      return result;
    },

    getTotalFees() {
      var total = 0;
      if (this.service && this.service.fees_user) {
        this.service.fees_user.forEach((fee) => {
          total += this.getFeeServiceFor(fee);
        });
      }

      return total;
    },

    selectPromotion() {
      var amount = this.form.amount;
      var promotions = this.service.promotions_user;
      var result = null;
      promotions.forEach((promo) => {
        if (promo.max_amount >= amount && promo.min_amount <= amount) {
          result = promo;
          return;
        }
      });

      return result;
    },

    promotionService() {
      var result = 0;
      var amount = this.form.amount;
      var promotion = this.selectPromotion();

      if (!promotion) {
        return result;
      }

      if (amount > promotion.max_amount) {
        result = (promotion.max_amount * promotion.discount) / 100;
      }
      return (amount * promotion.discount) / 100;
    },

    checkCode(code, service_id, amount) {
      this.loading = true;
      this.promo_code_error = false;
      HTTP.post("service/promo_code/check", {
        amount: amount,
        service_id: service_id,
        promo_code: code,
      })
        .then((res) => {
          this.promo_code_amount = res.data.data.promotion_code_amount;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.promo_code_error = true;
          this.promo_code_amount = 0;
        });
    },

    confirm() {
      this.$emit("confirm", {
        payment_mode: this.payment_mode,
        recaptcha_token: this.recaptcha_token,
      });
      this.close();
    },
  },

  computed: {
    getFeeService() {
      return this.feeService();
    },
    getPromotionService() {
      return this.promotionService();
    },
    getTotalAmount() {
      return (
        parseFloat(this.getTotalFees()) +
        parseFloat(this.form.amount) -
        this.promotionService() -
        this.promo_code_amount
      );
    },
  },
};
</script>

<style scoped>
.line-through {
  text-decoration: line-through;
  color: #444;
  margin-right: 8px;
}
</style>