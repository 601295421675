<template>
  <v-row class="mt-0">
    <v-col cols="12" class="pt-0">
      <v-card flat class="rounded-lg">
        <form @submit.prevent="saveChange">
          <v-card-text class="pa-lg-12">
            <v-alert
              dense
              v-if="error"
              color="red"
              dark
              icon="mdi-information-outline"
              border="left"
              prominent
            >
              {{ error }}
            </v-alert>
            <v-row>
              <v-col cols="12" class="justify-center align-center text-center">
                <v-avatar
                  elevation="2"
                  width="150"
                  height="150"
                  style="cursor: pointer; border: 5px solid #ddd"
                  @click="document.getElementById('uploader').click()"
                >
                  <v-img :src="avatar_url" min-width="150" />
                </v-avatar>
              </v-col>
              <v-col lg="6" cols="12">
                <!--  name -->
                <v-text-field
                  outlined
                  prepend-inner-icon="mdi-account-outline"
                  type="text"
                  v-model="form.name"
                  label="Nom Utilisateur *"
                  hide-details=""
                  :color="errors && errors.name ? 'red' : 'primary'"
                  :class="errors && errors.name ? 'redBorder' : ''"
                  @input="errors && errors.name ? (errors.name = '') : false"
                >
                </v-text-field>
                <p class="red--text">
                  {{ errors.name ? errors.name[0] : "" }}
                </p>
                <!-- End name -->
              </v-col>
              <v-col lg="6" cols="12">
                <!--  email -->
                <v-text-field
                  outlined
                  prepend-inner-icon="mdi-email-outline"
                  type="email"
                  v-model="form.email"
                  label="Email *"
                  hide-details=""
                  :color="errors && errors.email ? 'red' : 'primary'"
                  :class="errors && errors.email ? 'redBorder' : ''"
                  @input="errors && errors.email ? (errors.email = '') : false"
                >
                </v-text-field>
                <p class="red--text">
                  {{ errors.email ? errors.email[0] : "" }}
                </p>
                <!-- End email -->
              </v-col>
            </v-row>

            <v-row>
              <v-col lg="6" cols="12">
                <v-select
                  outlined
                  label="Pays *"
                  item-text="name"
                  :loading="isCountriesLoading"
                  item-value="id"
                  :items="countries"
                  v-model="form.country_id"
                  @change="countryChanged"
                  hide-details=""
                  :color="errors && errors.country_id ? 'red' : 'primary'"
                  :class="errors && errors.country_id ? 'redBorder' : ''"
                  @input="
                    errors && errors.country_id
                      ? (errors.country_id = '')
                      : false
                  "
                >
                  <template v-slot:item="slotProps">
                    <div class="d-flex align-center justify-center">
                      <div style="width: 50px">
                        <img
                          :src="getImageLink(slotProps.item.flag)"
                          max-height="25"
                          max-width="25"
                          width="25"
                          height="25"
                          class="p-2"
                        />
                      </div>

                      {{ slotProps.item.name }}
                    </div>
                  </template>
                </v-select>
                <p class="red--text">
                  {{ errors.country_id ? errors.country_id[0] : "" }}
                </p>
              </v-col>

              <v-col lg="6" cols="12">
                <v-select
                  outlined
                  label="Wilaya *"
                  item-text="name"
                  :loading="isWilayasLoading"
                  item-value="id"
                  :items="wilayas"
                  v-model="form.wilaya_id"
                  hide-details=""
                  :color="errors && errors.wilaya_id ? 'red' : 'primary'"
                  :class="errors && errors.wilaya_id ? 'redBorder' : ''"
                  @input="
                    errors && errors.wilaya_id ? (errors.wilaya_id = '') : false
                  "
                  @change="getCommunes"
                >
                </v-select>
                <p class="red--text">
                  {{ errors.wilaya_id ? errors.wilaya_id[0] : "" }}
                </p>
              </v-col>

              <v-col lg="6" cols="12" v-if="user.salepoint">
                <v-select
                  outlined
                  label="Commune *"
                  item-text="name"
                  :loading="isCommunesLoading"
                  item-value="id"
                  :items="communes"
                  v-model="form.commune_id"
                  hide-details=""
                  :color="errors && errors.commune_id ? 'red' : 'primary'"
                  :class="errors && errors.commune_id ? 'redBorder' : ''"
                  @input="
                    errors && errors.commune_id
                      ? (errors.commune_id = '')
                      : false
                  "
                >
                </v-select>
                <p class="red--text">
                  {{ errors.commune_id ? errors.commune_id[0] : "" }}
                </p>
              </v-col>

              <v-col lg="6" cols="12" v-if="user.salepoint">
                <v-text-field
                  outlined
                  prepend-inner-icon="mdi-map-marker-outline"
                  type="text"
                  v-model="form.adress"
                  label="Addresse *"
                  hide-details=""
                  :color="errors && errors.adress ? 'red' : 'primary'"
                  :class="errors && errors.adress ? 'redBorder' : ''"
                  @input="
                    errors && errors.adress ? (errors.adress = '') : false
                  "
                >
                </v-text-field>
                <p class="red--text">
                  {{ errors.adress ? errors.adress[0] : "" }}
                </p>
              </v-col>

              <!-- <v-col lg="6" cols="12">
                <v-text-field
                  outlined
                  prepend-inner-icon="mdi-phone-outline"
                  type="phone"
                  v-model="form.phone"
                  label="Téléphone *"
                  :prefix="phoneCode + ''"
                  hide-details=""
                  :color="errors && errors.phone ? 'red' : 'primary'"
                  :class="errors && errors.phone ? 'redBorder' : ''"
                  @input="errors && errors.phone ? (errors.phone = '') : false"
                >
                </v-text-field>
                <p class="red--text">
                  {{ errors.phone ? errors.phone[0] : "" }}
                </p>
              </v-col> -->

              <v-col lg="6" cols="12">
                <v-menu
                  v-model="dateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="form.dob"
                      label="Date de naissance *"
                      prepend-inner-icon="mdi-calendar-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      hide-details=""
                      :color="errors && errors.dob ? 'red' : 'primary'"
                      :class="errors && errors.dob ? 'redBorder' : ''"
                      @input="errors && errors.dob ? (errors.dob = '') : false"
                    ></v-text-field>
                    <p class="red--text">
                      {{ errors.dob ? errors.dob[0] : "" }}
                    </p>
                  </template>
                  <v-date-picker
                    :max="moment().format('YYYY-MM-DD')"
                    v-model="form.dob"
                    @input="dateMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col lg="6" cols="12">
                <v-radio-group
                  v-model="form.gender"
                  row
                  hide-details=""
                  class="mt-0"
                >
                  <v-radio
                    v-for="item in genders"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></v-radio>
                </v-radio-group>
                <p class="red--text">
                  {{ errors.gender ? errors.gender[0] : "" }}
                </p>
              </v-col>

              <v-col>
                <div class="d-flex align-center">
                  <v-file-input
                    id="uploader"
                    hide-input
                    outlined
                    prepend-inner-icon="mdi-camera"
                    label="Image *"
                    accept="image/png, image/jpeg, image/jpg, .svg, .gif"
                    show-size
                    suffix="Image"
                    @change="uploadFile"
                    @focus="
                      errors && errors.image ? (errors.image = '') : false
                    "
                    ref="file"
                    chips
                    prepend-icon=""
                    :error-messages="errors.image"
                  />
                </div>
              </v-col>
            </v-row>

            <v-btn
              type="submit"
              depressed
              :loading="btnLoading"
              large
              block
              color="primary"
              class="rounded-lg mt-3 mb-5"
            >
              Enregister
            </v-btn>
          </v-card-text>
        </form>
      </v-card>

      <v-card flat class="rounded-lg mt-5">
        <v-card-text class="pa-lg-12">
          <v-alert
            dense
            v-if="errorPassword"
            color="red"
            dark
            icon="mdi-information-outline"
            border="left"
            prominent
          >
            {{ errorPassword }}
          </v-alert>

          <v-row>
            <v-col md="6" cols="12">
              <v-alert text color="info" class="mt-5">
                <p>Votre mot de passe doit contenir les éléments suivant :</p>
                <span class="d-block">8 Caractère minimum</span>
                <span class="d-block">1 Majuscule / minuscule</span>
                <span class="d-block">1 Chiffre</span>
                <span class="d-block">1 Caractere spécial (@,& ...)</span>
              </v-alert>
            </v-col>
            <v-col md="6" cols="12">
              <v-text-field
                outlined
                prepend-inner-icon="mdi-lock-outline"
                v-model="formPassword.password"
                :type="show ? 'text' : 'password'"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="show = !show"
                label="Mot de passe *"
                :color="errors && errors.password ? 'red' : 'primary'"
                :class="errors && errors.password ? 'redBorder' : ''"
                hide-details=""
              >
              </v-text-field>
              <p class="red--text">
                {{ errors.password ? errors.password[0] : "" }}
              </p>

              <v-text-field
                outlined
                prepend-inner-icon="mdi-lock-outline"
                v-model="formPassword.new_password"
                :errors-messages="errors ? errors.new_password : ''"
                :type="show ? 'text' : 'password'"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="show = !show"
                label="Nouveau Mot de passe *"
                :color="errors && errors.new_password ? 'red' : 'primary'"
                :class="errors && errors.new_password ? 'redBorder' : ''"
                hide-details=""
              >
              </v-text-field>
              <p class="red--text">
                {{ errors.new_password ? errors.new_password[0] : "" }}
              </p>

              <v-text-field
                outlined
                prepend-inner-icon="mdi-lock-outline"
                v-model="formPassword.new_password_confirmation"
                :errors-messages="
                  errors ? errors.new_password_confirmation : ''
                "
                :type="show ? 'text' : 'password'"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="show = !show"
                label="Confirmez votre nouveau Mot de passe *"
                :color="
                  errors && errors.new_password_confirmation ? 'red' : 'primary'
                "
                :class="
                  errors && errors.new_password_confirmation ? 'redBorder' : ''
                "
                hide-details=""
              >
              </v-text-field>
              <p class="red--text">
                {{
                  errors.new_password_confirmation
                    ? errors.new_password_confirmation[0]
                    : ""
                }}
              </p>

              <v-btn
                depressed
                :loading="btnLoading"
                large
                block
                color="primary"
                class="rounded-lg mt-3 mb-5"
                @click="resetPassword"
              >
                Enregister
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { HTTP } from "@/http-common";
import moment from "moment";
export default {
  data() {
    return {
      document,

      btnLoading: false,
      isCountriesLoading: false,
      isCategoriesLoading: false,
      isTypesLoading: false,
      isWilayasLoading: false,
      isCommunesLoading: false,

      countries: [],
      categories: [],
      types: [],
      wilayas: [],
      communes: [],

      error: null,
      errorPassword: null,
      errors: {},
      show: false,
      dateMenu: false,
      phoneCode: "",

      form: {
        country_id: null,
        wilaya_id: null,
        adress: null,
        name: "",
        dob: moment().format("YYYY-MM-DD"),
        gender: null,
        image: null,
      },
      avatar_url: null,
      genders: [
        {
          id: "male",
          name: "Homme",
        },
        {
          id: "female",
          name: "Femme",
        },
      ],

      formPassword: {
        password: null,
        new_password: null,
        new_password_confirmation: null,
      },
    };
  },
  methods: {
    fetchData() {
      this.getCategories();
      this.getCountries();
      this.getWilayas();
      this.getTypes();

      this.initForm();
    },

    initForm() {
      this.avatar_url = this.getImageLink(this.user.image);
      var user = this.$store.getters.getUser.user;
      if (user.dob) {
        this.form.dob = moment(user.dob).format("YYYY-MM-DD");
      }
      this.form.id = user.id;
      this.form.name = user.name;
      this.form.email = user.email;
      this.form.gender = user.gender;
      this.form.country_id = user.country_id;
      this.form.wilaya_id = user.wilaya_id;

      if (user.salepoint) {
        this.getCommunes();
        this.form.commune_id = user.salepoint.commune_id;
        this.form.adress = user.salepoint.adress;
      }
    },

    getCountries() {
      this.isCountriesLoading = true;
      HTTP.get("v1/countries")
        .then((res) => {
          this.isCountriesLoading = false;
          this.countries = res.data.data;
          var user = this.$store.getters.getUser.user;
          this.form.country_id = user.country_id;

          var id = this.form.country_id;
          var country = this.countries.find((x) => x.id === id);

          this.phoneCode = "+" + country.phonecode + "";
          var phone = user.phone;
          phone = phone.substring(this.phoneCode.length);
          this.form.phone = phone;
        })
        .catch((err) => {
          this.isCountriesLoading = false;
          console.log(err);
        });
    },
    getCategories() {
      this.isCategoriesLoading = true;
      HTTP.get("v1/users/categories")
        .then((res) => {
          this.isCategoriesLoading = false;
          this.categories = res.data.data;
        })
        .catch((err) => {
          this.isCategoriesLoading = false;
          console.log(err);
        });
    },

    getWilayas() {
      this.isWilayasLoading = true;
      HTTP.get("wilaya/all")
        .then((res) => {
          this.isWilayasLoading = false;
          this.wilayas = res.data;
        })
        .catch((err) => {
          this.isWilayasLoading = false;
          console.log(err);
        });
    },

    getCommunes() {
      this.isCommunesLoading = true;
      HTTP.get("v1/commune/" + this.form.wilaya_id)
        .then((res) => {
          this.isCommunesLoading = false;
          this.communes = res.data;
          // console.log(res);
        })
        .catch((err) => {
          this.isCommunesLoading = false;
          console.log(err);
        });
    },

    getTypes() {
      this.isTypesLoading = true;
      HTTP.get("salepoint-type")
        .then((res) => {
          this.isTypesLoading = false;
          this.types = res.data;
        })
        .catch((err) => {
          this.isTypesLoading = false;
          console.log(err);
        });
    },

    countryChanged() {
      var id = this.form.country_id;
      var country = this.countries.find((x) => x.id === id);

      this.phoneCode = "+" + country.phonecode;
    },

    saveChange() {
      this.errors = {};
      this.error = "";
      
      let formData = new FormData();
      Object.keys(this.form).forEach((key) => {
        formData.append(key, this.form[key]);
      });
      
      this.btnLoading = true;
      HTTP.post("/v1/gifty/update_settings", formData)
        .then((res) => {
          console.log(res);

          this.$refs.file.reset();
          var user = res.data.data.user;

          this.$store.dispatch("refreshUser", user);
          // dispatch user
          console.log(user);
          this.btnLoading = false;

          this.$successMessage = "Enregistrement avec succés";
        })
        .catch((err) => {
          console.log(err);
          this.btnLoading = false;
          this.errors = err.response.data.errors;

          this.error = "Une erreur s'est produite veuillez corriger.";
        });
    },

    resetPassword() {
      this.errorPassword = null;
      this.btnLoading = true;
      HTTP.post("/v1/gifty/update_password", this.formPassword)
        .then(() => {
          this.btnLoading = false;

          this.$successMessage = "Enregistrement avec succés";
        })
        .catch((err) => {
          console.log(err);
          this.btnLoading = false;
          this.errors = err.response.data.errors;
          this.formPassword = {}

          this.errorPassword = "Une erreur s'est produite veuillez corriger.";
        });
    },
    uploadFile(file) {
      this.form.image = file;
      this.avatar_url = URL.createObjectURL(this.form.image);
    },
  },
  mounted() {
    this.fetchData();
    
  },

  computed: {
    user() {
      return this.$store.getters.getUser.user;
    },
  },
};
</script>