var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"rounded-lg shadow mb-3"},[_c('v-card-text',[_c('v-row',{staticClass:"align-center"},[_c('v-col',[_c('h3',{staticClass:"mb-2"},[_vm._v("Carte "+_vm._s(_vm.transaction.type))]),_c('h5',{staticClass:"grey--text mb-1"},[_vm._v(_vm._s(_vm.transaction.created_at))]),_c('h4',{staticClass:"lighten-3 mb-1 blue--text darken-2 align-center d-flex"},[_c('v-icon',{staticClass:"blue--text darken-2 mr-2"},[_vm._v("mdi-credit-card-check")]),_vm._v(" "+_vm._s(_vm.transaction.idoom_card ? _vm.transaction.idoom_card.card.card_number : "-")+" ")],1),_c('span',{staticClass:"font-weight-medium blue--text darken-2"},[_vm._v(_vm._s(_vm.CurrencyFormatting(_vm.transaction.amount))+" DZD ")]),_c('br'),_c('span',{staticClass:"font-weight-medium mt-2 primary--text darken-2"},[_vm._v(" Payé: "+_vm._s(_vm.CurrencyFormatting(_vm.transaction.total_amount))+" Pts ")])]),_c('v-spacer'),_c('v-col',{staticClass:"text-center"},[_c('v-avatar',{attrs:{"color":'blue'}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(_vm._s("mdi-credit-card"))])],1),_c('br'),(
            _vm.transaction.idoom_card &&
            _vm.transaction.idoom_card.card &&
            _vm.transaction.idoom_card.card.martship_card
          )?_c('v-chip',{staticClass:"ma-2",attrs:{"small":"","color":_vm.$martship_states.colors[
              _vm.transaction.idoom_card.card.martship_card.martship_state
            ],"text-color":"white"}},[_vm._v(" "+_vm._s(_vm.$martship_states.texts[ _vm.transaction.idoom_card.card.martship_card.martship_state ])+" ")]):_c('v-chip',{staticClass:"ma-2",attrs:{"small":"","color":"success","text-color":"white"}},[_vm._v(" Acceptée ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }