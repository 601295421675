var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticClass:"mt-4"},[(_vm.publicitiesLoading)?_c('v-col',{attrs:{"cols":"12"}},[_c('carousel',_vm._l((5),function(i){return _c('slide',{key:i},[_c('v-skeleton-loader',{staticClass:"mr-3",attrs:{"type":"article"}})],1)}),1)],1):_c('carousel',{staticStyle:{"width":"100%"},attrs:{"autoplay":true,"perPageCustom":[
      [100, 1],
      [200, 1],
      [300, 1],
      [500, 1],
      [768, 2],
      [900, 2],
      [1024, 2],
    ],"navigationEnabled":false,"loop":true,"minSwipeDistance":15,"paginationColor":"#fff","paginationSize":0}},_vm._l((_vm.publicities),function(publicity){return _c('slide',{key:publicity.id},[_c('v-card',{staticClass:"rounded mb-3 mx-3",staticStyle:{"cursor":"pointer","min-width":"90%"},on:{"click":function($event){return _vm.openPubliciy(publicity)}}},[_c('v-img',{attrs:{"aspect-ratio":16 / 9,"src":_vm.getImageLink(publicity.image)}})],1)],1)}),1),_c('FormCard',{ref:"formCard"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }