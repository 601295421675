<template>
  <v-card class="rounded-lg shadow mb-3">
    <v-card-text>
      <v-row class="align-center">
        <v-col class="justify-center">
          <h3 class="mb-0">
            Voucher ({{
              transaction.voucher
                ? transaction.voucher.voucher
                  ? transaction.voucher.voucher.supplier
                    ? transaction.voucher.voucher.supplier.name
                    : "-"
                  : "-"
                : "-"
            }})
          </h3>
          <h5 class="grey--text mb-1">{{ transaction.created_at }}</h5>
          <h4 class="mb-1 grey--text lighten-4 primary--text">
            <v-icon class=" primary--text mr-2"> mdi-key-link </v-icon>

            <span class="">{{
              (transaction.voucher &&  transaction.voucher.choice != 'sms') 
                ? transaction.voucher.voucher
                  ? transaction.voucher.voucher.code
                  : "-"
                : "-"
            }}</span>
          </h4>
          <span class="font-weight-medium mt-2 primary--text darken-2"
            >{{ CurrencyFormatting(transaction.amount) }} DZD</span>
          <br>
          <span class="font-weight-medium mt-2 primary--text darken-2">
            Payé: {{ CurrencyFormatting(transaction.total_amount) }} Pts
          </span>
        </v-col>
        <v-col class="text-right">
          <v-avatar color="primary">
            <v-icon dark>mdi-ticket</v-icon>
          </v-avatar>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: ["transaction"],
  data() {
    return {};
  },
};
</script>