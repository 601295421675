<template>
  <div>
    <div v-if="!loading">
      <FullscreenItem v-for="item in pubs" :item="item" :key="item.id" />
    </div>
  </div>
</template>

<script>
import { HTTP } from "@/http-common";
import FullscreenItem from "./FullscreenItem.vue";
export default {
  data() {
    return {
      pubs: [],
      loading: true,
    };
  },
  methods: {
    fecthData() {
      this.loading = true;
      HTTP.get("/v1/mobile/publicities/fullscreen")
        .then((res) => {
          this.pubs = res.data;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
  },
  mounted() {
    this.fecthData();
  },
  components: { FullscreenItem },
};
</script>

<style scoped>
</style>