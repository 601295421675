<template>
  <div>
    <v-row>
      <v-col cols="12 ">
        <h2 class="primary--text">Dernières transactions</h2>
      </v-col>
    </v-row>

    <v-row v-if="lastTransactionsLoading">
      <v-col cols="12" md="6" lg="4" v-for="i in 6" :key="i" class="mb-3">
        <v-skeleton-loader type="article"></v-skeleton-loader>
      </v-col>
    </v-row>
    <div v-else>
      <v-row v-if="lastTransactions.length > 0">
        <v-col
          cols="12"
          md="6"
          lg="4"
          v-for="transaction in lastTransactions"
          :key="transaction.id"
        >
          <VoucherTransaction
            v-if="transaction.type === 'voucher'"
            :transaction="transaction"
          />
          <RefillTransaction
            v-if="
              transaction.type === 'sent_refill' ||
              transaction.type === 'received_refill'
            "
            :transaction="transaction"
            @acceptRefill="acceptRefill($event)"
          />
          <FlexyTransaction
            v-if="transaction.type === 'flexy'"
            :transaction="transaction"
          />
          <CardTransaction
            v-if="transaction.type === 'Idoom' || transaction.type === '4G'"
            :transaction="transaction"
          />
        </v-col>
      </v-row>
      <div v-else class="text-center">
        <v-avatar tile size="200">
          <!-- <v-img :src="require('@/assets/database.svg')"></v-img> -->
          <v-icon size="120" class="gifty--text lighten-2">mdi-swap-horizontal</v-icon>
        </v-avatar>
        <p>Aucun résultat  </p>
      </div>
    </div>

    <AcceptRefill ref="acceptRefill"/>
  </div>
</template>

<script>
import { HTTP } from "@/http-common";
import VoucherTransaction from "./components/VoucherTransaction.vue";
import CardTransaction from "./components/CardTransaction.vue";
import RefillTransaction from "./components/RefillTransaction.vue";
import FlexyTransaction from "./components/FlexyTransaction.vue";
import AcceptRefill from "./services/AcceptRefill.vue";


export default {
  components: {
    VoucherTransaction,
    FlexyTransaction,
    CardTransaction,
    RefillTransaction,
    AcceptRefill
  },
  data() {
    return {
      lastTransactions: [],
      lastTransactionsLoading: false,
    };
  },
  methods: {
    getLastTransaction() {
      this.lastTransactionsLoading = true;
      HTTP.get("/v1/gifty/last_transactions")
        .then((res) => {
          this.lastTransactions = res.data.data;
          this.lastTransactionsLoading = false;
        })
        .catch((err) => {
          this.lastTransactionsLoading = false;
          console.log(err);
        });
    },
    acceptRefill(refill){
      this.$refs.acceptRefill.open(refill);
    }
  },
  mounted() {
    this.getLastTransaction();
  },
};
</script>

