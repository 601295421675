<template>
  <div>
    <v-row>
      <!-- <v-col cols="12">
        <h3>Types Services</h3>
        <Types @setType="setType($event)"/>
      </v-col> -->

      <v-col cols="12">
        <h2 class="primary--text">Catégories</h2>
        <Categories @setCategory="setCategory($event)" />
      </v-col>
    </v-row>
    <v-col cols="12">
      <h2 class="primary--text">Services</h2>
    </v-col>
    <v-row v-if="servicesLoading">
      <v-col cols="6" md="3" lg="2" v-for="index in 8" :key="index">
        <v-skeleton-loader type="article" />
      </v-col>
    </v-row>
    <v-row v-else class="justify-center">
      <v-col
        cols="6"
        md="3"
        lg="2"
        v-for="service in services"
        :key="service.id"
      >
        <service-item
          :service="service"
          @consumeService="consumeService($event)"
        ></service-item>
      </v-col>
    </v-row>

    <form-voucher ref="formVoucher"/>
    <FormFlexy ref="formFlexy" />
    <FormRefill ref="formRefill" />
    <FormCard ref="formCard" />
    

    <ServicePrices
      ref="servicePrices"
      @consumeServiceAmount="consumeServiceAmount($event)"
      @orderService="orderService($event)"
    />
    
  </div>
</template>

<script>
import { HTTP } from "@/http-common";
import Categories from "./Categories.vue";
import Types from "./Types.vue";
import ServiceItem from "./ServiceItem.vue";
import ServicePrices from "./ServicePrices.vue";

import FormVoucher from "./FormVoucher.vue";
import FormFlexy from "./FormFlexy.vue";
import FormRefill from "./FormRefill.vue";
import FormCard from "./FormCard.vue";


export default {
  components: {
    Categories,
    ServiceItem,
    ServicePrices,
    FormVoucher,
    FormFlexy,
    FormRefill,
    FormCard,
    
  },
  data() {
    return {
      category: null,
      type: null,
      filter: {
        categories: [],
        types: [],
      },

      // services
      services: [],
      servicesLoading: false,
    };
  },
  methods: {
    getServices() {
      this.servicesLoading = true;
      HTTP.get("/v1/gifty/services", {
        params: {
          ...this.filter,
        },
      })
        .then((res) => {
          this.services = res.data.data;
          this.servicesLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.servicesLoading = false;
        });
    },
    setCategory(category) {
      this.category = category;
      if (category == null) {
        this.filter.categories = [];
      } else {
        this.filter.categories = [category.id];
      }

      this.getServices();
    },
    setType(type) {
      this.type = type;
      if (type == null) {
        this.filter.types = [];
      } else {
        this.filter.types = [type.id];
      }

      this.getServices();
    },
    consumeService(service) {
      // if (service.type.name == "Voucher") {
      //   this.$refs.formVoucher.open(service);
      // }

      // if (service.type.name == "Carte") {
      //   this.$refs.formCard.open(service);
      // }

      if (service.type.name == "Voucher" || service.type.name == "Carte") {
        this.$refs.servicePrices.open(service);
      }

      if (service.type.name == "Flexy") {
        this.$refs.formFlexy.open(service);
      }

      if (service.type.name == "Refill") {
        this.$refs.formRefill.open(service);
      }
    },

    consumeServiceAmount(event) {
      var amount = event.amount;
      var service = event.service;

      if (service.type.name == "Voucher") {
        this.$refs.formVoucher.openAmount(service, amount);
      }

      if (service.type.name == "Carte") {
        this.$refs.formCard.openAmount(service, amount);
      }
    },

    orderService(service) {
      this.$refs.formVoucher.openOrder(service);
    },

    
  },
  mounted() {
    this.getServices();
    
  },
};
</script>