<template>
  <div>
    <v-dialog v-model="dialog" max-width="500" persistent round>
      <v-card v-if="service">
        <v-card-title>
          {{ title }}
          <v-spacer />
          <v-btn icon class="v-btn--active" color="primary" @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text class="pa-6">
          <p class="text-center">
            <v-btn color="gifty" elevation="0" rounded class="white--text">
              Solde Global:
              {{
                CurrencyFormatting(
                  $store.state.user.user.account.balance +
                    $store.state.user.user.account.credit_balance
                )
              }}
              DZD
            </v-btn>
          </p>
          <p class="text-center">
            <v-btn color="gifty" elevation="0" rounded class="white--text mt-2">
              Solde Service: {{ CurrencyFormatting(service.user_balance) }} DZD
            </v-btn>
          </p>

          <p class="text-center">
            <v-btn
              text
              color="primary"
              @click="details"
              v-if="service.description"
            >
              <v-icon class="mr-3">mdi-image-text</v-icon>
              Details
            </v-btn>
          </p>
          <v-alert
            dense
            v-if="errors && errors.operation"
            color="red"
            dark
            icon="mdi-information-outline"
            border="left"
            prominent
          >
            {{ errors.operation[0] }}
          </v-alert>

          <v-row v-if="form.choice == null">
            <v-col lg="6" sm="12">
              <v-card color="gifty" flat rounded @click="setChoice('account')">
                <v-card-text class="text-center py-5">
                  <v-icon class="white--text" size="70">mdi-account</v-icon>
                  <h4 class="white--text">Convertir sur mon compte</h4>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col lg="6" sm="12">
              <v-card color="gifty" flat rounded @click="setChoice('sms')">
                <v-card-text class="text-center py-5">
                  <v-icon class="white--text" size="70">mdi-message</v-icon>
                  <h4 class="white--text">Envoyer le code par SMS</h4>
                </v-card-text>
              </v-card>
            </v-col>
            <!-- 
          <v-col lg="6" sm="12" v-if="service.with_order">
            <v-card color="gifty" flat rounded @click="setChoice('order')">
              <v-card-text class="text-center py-5">
                <v-icon class="white--text" size="70">mdi-file-document</v-icon>
                <h4 class="white--text">Commande</h4>
              </v-card-text>
            </v-card>
          </v-col> -->
          </v-row>
          <div v-else>
            <v-row>
              <v-col cols="12" class="mb-0">
                <div class="d-flex" v-if="form.choice == 'sms'">
                  <!-- <div style="width: 30%">
                  <v-select
                    outlined
                    label="Pays *"
                    item-text="name"
                    :loading="isCountriesLoading"
                    item-value="id"
                    :items="countries"
                    v-model="country_id"
                    @change="countryChanged"
                    hide-details=""
                  >
                    <template v-slot:item="slotProps">
                      <div class="d-flex align-center justify-center">
                        <div style="width: 50px">
                          <img
                            :src="getImageLink(slotProps.item.flag)"
                            max-height="25"
                            max-width="25"
                            width="25"
                            height="25"
                            class="p-2"
                          />
                        </div>

                        {{ slotProps.item.name }}
                      </div>
                    </template>
                  </v-select>
                  <p class="red--text">
                    {{ errors.country_id ? errors.country_id[0] : "" }}
                  </p>
                </div> -->

                  <div style="width: 100%">
                    <v-text-field
                      outlined
                      prepend-inner-icon="mdi-phone-outline"
                      type="number"
                      v-model="form.number"
                      label="Téléphone *"
                      :prefix="phoneCode + ''"
                      hide-details=""
                      :color="errors && errors.number ? 'red' : 'primary'"
                      :class="errors && errors.number ? 'redBorder' : ''"
                      @input="
                        errors && errors.number ? (errors.number = '') : false
                      "
                      style="margin-left: 5px"
                    >
                    </v-text-field>
                    <p class="red--text">
                      {{ errors.number ? errors.number[0] : "" }}
                    </p>
                    <!-- End number -->
                  </div>
                </div>

                <!-- <v-checkbox
                label="Avec un Montant Personalisé"
                v-model="amount_dynamic"
                v-if="service.with_build_vouchers"
              /> -->

                <v-select
                  v-if="!amount_dynamic && !(this.form.choice == 'order')"
                  outlined
                  disabled
                  label="Montant *"
                  :loading="isAmountsLoading"
                  :items="amounts"
                  v-model="form.amount"
                  hide-details=""
                  :color="errors && errors.amount ? 'red' : 'primary'"
                  :class="errors && errors.amount ? 'redBorder' : ''"
                  @input="
                    errors && errors.amount ? (errors.amount = '') : false
                  "
                  class="mb-0 mt-o"
                >
                  <template v-slot:item="slotProps">
                    <div class="d-flex align-center justify-center">
                      {{ CurrencyFormatting(slotProps.item) }} DZD
                    </div>
                  </template>
                </v-select>
                <p class="red--text">
                  {{ errors.amount ? errors.amount[0] : "" }}
                </p>
                <!--  Amount -->
                <v-text-field
                  v-if="amount_dynamic || this.form.choice == 'order'"
                  outlined
                  prepend-inner-icon="mdi-currency-usd"
                  type="number"
                  v-model="form.amount"
                  label="Montant Personalisé *"
                  hide-details=""
                  :color="errors && errors.amount ? 'red' : 'primary'"
                  :class="errors && errors.amount ? 'redBorder' : ''"
                  @input="
                    errors && errors.amount ? (errors.amount = '') : false
                  "
                >
                </v-text-field>
                <p class="red--text" v-if="amount_dynamic">
                  {{ errors.amount ? errors.amount[0] : "" }}
                </p>
                <!-- End amount -->

                <v-checkbox
                  v-model="with_promo_code"
                  label="Avez vous un code promotion ?"
                />
                <!--  promo_code -->
                <v-text-field
                  v-if="with_promo_code"
                  outlined
                  prepend-inner-icon="mdi-ticket-percent"
                  type="text"
                  v-model="form.promo_code"
                  label="Code Promotion*"
                  hide-details=""
                  :color="errors && errors.promo_code ? 'red' : 'primary'"
                  :class="errors && errors.promo_code ? 'redBorder' : ''"
                  @input="
                    errors && errors.promo_code
                      ? (errors.promo_code = '')
                      : false
                  "
                >
                </v-text-field>
                <p class="red--text" v-if="with_promo_code">
                  {{ errors.promo_code ? errors.promo_code[0] : "" }}
                </p>
              </v-col>
            </v-row>

            <v-btn
              type="submit"
              @click="openCheckount()"
              depressed
              :loading="btnLoading"
              large
              block
              color="primary"
              class="rounded-lg mt-3 mb-5"
            >
              Confirmer
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <ServiceDescription ref="serviceDescription" />
    <Checkout ref="checkout" @confirm="confirmCheckout($event)" />
  </div>
</template>

<script>
import { HTTP } from "@/http-common";
import ServiceDescription from "./ServiceDescription.vue";
import Checkout from "./Checkout.vue";

export default {
  components: { ServiceDescription, Checkout },
  data() {
    return {
      dialog: false,
      service: null,
      amount_dynamic: false,

      // Loadings
      btnLoading: false,
      isCountriesLoading: false,
      isAmountsLoading: false,

      // countries
      countries: [],
      amounts: [],

      phoneCode: "+213",
      error: "",
      errors: {},
      country_id: 1,

      form: {
        choice: null,
        number: null,
        amount: null,
        account_id: this.$store.state.user.user.account.id,
        promo_code: "",
      },
      with_promo_code: false,
    };
  },
  methods: {
    open(service) {
      this.service = service;
      this.dialog = true;
      this.getCountries();
      this.getAmounts();
    },

    openAmount(service, amount) {
      if (amount) {
        this.form.amount = amount;
      } else {
        this.amount_dynamic = true;
      }
      this.open(service);
    },

    openOrder(service) {
      this.form.choice = "order";
      this.open(service);
    },
    openCheckount() {
      this.$refs.checkout.open(this.form, this.service, this.with_promo_code);
    },

    close() {
      this.form.choice = null;
      this.dialog = false;
      this.form.promo_code = "";
      this.with_promo_code = false;
      this.errors = {};
    },
    setChoice(choice) {
      this.form.choice = choice;
    },
    getCountries() {
      this.isCountriesLoading = true;
      HTTP.get("v1/countries")
        .then((res) => {
          this.isCountriesLoading = false;
          this.countries = res.data.data;
        })
        .catch((err) => {
          this.isCountriesLoading = false;
          console.log(err);
        });
    },
    getAmounts() {
      this.isAmountsLoading = true;
      HTTP.get("v1/mobile/services/vouchers/" + this.service.id)
        .then((res) => {
          

          var data = [];
          res.data.map((item) => {
            
            if(item.split("-").length > 1){
              data.push(item.split("-")[0]);
            }else{
              data.push( item);
            }
          });


          this.amounts = data;
          this.isAmountsLoading = false;
        })
        .catch((err) => {
          this.isAmountsLoading = false;
          console.log(err);
        });
    },
    countryChanged() {
      var id = this.country_id;
      var country = this.countries.find((x) => x.id === id);

      this.phoneCode = "+" + country.phonecode;
    },
    save() {
      this.errors = {};
      var url = "v1/gifty/services/consume/";
      if (this.form.choice == "order") {
        url = "v1/gifty/services/order/";
      }
      var promo_code = "";
      if (this.with_promo_code) {
        promo_code = this.form.promo_code;
      }

      if (this.with_promo_code && !promo_code) {
        this.errors = {
          promo_code: ["Ce champ est obligatore"],
        };
        return;
      }

      this.btnLoading = true;

      var formData = {
        choice: this.form.choice,
        number: this.phoneCode ? this.phoneCode + this.form.number : "",
        amount: this.form.amount,
        account_id: this.form.account_id,
        mode: this.form.mode,
      };

      if (this.with_promo_code) {
        formData.promo_code = this.form.promo_code;
      }

      HTTP.post(url + this.service.id, formData)
        .then(() => {
          this.btnLoading = false;
          this.dialog = false;
          this.$successMessage = "opération réalisée avec succès";
          this.$store.dispatch("fetchAccount");
          this.close();
        })
        .catch((err) => {
          this.btnLoading = false;
          console.log(err);
          this.errors = err.response.data.errors;

          this.error = "Une erreur s'est produite veuillez corriger.";
        });
    },

    details() {
      this.$refs.serviceDescription.open(this.service);
    },

    confirmCheckout(data) {

      if (data.payment_mode == "gifty") {
        this.save();
      }

      if (data.payment_mode == "cib") {
        this.pay(data.recaptcha_token);
      }
    },
    pay(recaptcha_token) {
      var promo_code = "";
      if (this.with_promo_code) {
        promo_code = this.form.promo_code;
      }

      if (this.with_promo_code && !promo_code) {
        this.errors = {
          promo_code: ["Ce champ est obligatore"],
        };
        return;
      }

      this.btnLoading = true;

      var formData = {
        choice: this.form.choice,
        number: this.phoneCode + this.form.number,
        amount: this.form.amount,
        account_id: this.form.account_id,
        mode: this.form.mode,

        // added to pay
        consume_service_id: this.service.id,
        consume_type: this.form.choice == "order" ? "order" : "consume",
        payment_method: "cib",
        promo_code : this.form.promo_code
      };

      HTTP.post("v1/e-payments/store", formData)
        .then((res) => {
          this.btnLoading = false;
          window.location = res.data.data.checkout_url;

        })
        .catch((err) => {
          this.btnLoading = false;
          this.errors = err.response.data.errors;
          this.error = "Une erreur s'est produite, veuillez verifier le formulaire.";
        });
    },
  },
  computed: {
    title() {
      if (this.form.choice == "order") {
        return "Commander un bon d'achat";
      }
      return "Convertir un bon d'achat";
    },
  },
};
</script>