<template>
  <v-card class="rounded-lg shadow" color="gifty" dark>
    <v-card-text class="p-5">
      <v-row class="align-center">
        <v-col>
          <h2 class="mb-2 white--text">Votre Solde total</h2>
          <h1 class="yellow--text lighten-1 ont-weight-medium mt-5">
             {{
              CurrencyFormatting(
                $store.getters.getUser.user.account.all_balance
              )
            }}
            <small>Pts</small>
            <v-btn @click="refreshBalance" icon class="ml-3"><v-icon>
              mdi-refresh
            </v-icon></v-btn>
          </h1>
        </v-col>
        <v-col class="text-right">
          <img src="../../../assets/logo-Récupéré.png" height="100px" />
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col cols="8">
          <span class="white--text"></span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  methods: {
    refreshBalance(){
      this.$store.dispatch("fetchAccount");
    }
  }
};
</script>