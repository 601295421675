<template>
  <v-row>
    <v-col cols="12">
      <v-skeleton-loader v-if="isLoading" type="table" />

      <div v-else>
        <statistiques-account :account="data.account" />

        <v-row>
          <v-col cols="12">
            <v-divider class="mt-3 mb-5" />
            <h3 class="text-center">Balance Et Frais Service</h3>
          </v-col>
          <v-col
            cols="6"
            lg="4"
            v-for="service in data.services"
            :key="service.id"
          >
            <v-sheet :rounded="'lg'" style="height: 100%" class="text-center">
              <v-card-title class="align-center text-center">
                <div
                  class=" text-center mt-2"
                  color="white"
                  style="margin: auto"
                >
                  <img :src="getImageLink(service.image)" 
                    width="100%" height="100" 
                    style="object-fit: contain" />
              </div>
              </v-card-title>

              <v-card-text class="align-start">
                <p class="font-weight-bold text-lg mb-1">{{ service.name }}</p>
                <p
                  class="
                    font-weight-black
                    text-lg
                    mb-1
                    title
                    success--text
                    darken-2
                  "
                >
                  {{ CurrencyFormatting(service.user_balance) }}
                  DZD
                </p>

                <p>
                  <v-btn class="btn success rounded-lg white--text py-4 mb-1" small
                  v-if="service.promotion_user" elevation="0">
                    Promo:  {{ service.promotion_user.discount }} % - Max: {{ CurrencyFormatting(service.promotion_user.max_amount) }} DZD
                  </v-btn><br/>

                  <v-btn class="btn primary rounded-lg white--text py-4 mb-1" small v-if="service.fee_user" elevation="0">
                    Frais:  {{ service.fee_user.value }} % - Max: {{ CurrencyFormatting(service.fee_user.max_amount) }} DZD
                  </v-btn>
                  
                </p>
              </v-card-text>
            </v-sheet>
          </v-col>
        </v-row>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { HTTP } from "@/http-common";
import StatistiquesAccount from "./StatistiquesAccount.vue";
export default {
  components: {StatistiquesAccount},
  data() {
    return {
      isLoading: true,
      data: {},
    };
  },
  methods: {
    fetchData() {
      this.isLoading = true;
      HTTP.get("/v1/gifty/statistiques")
        .then((res) => {
          this.data = res.data;
          this.isLoading = false;
        })
        .catch((e) => {
          console.log(e);
          this.isLoading = false;
        });
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>