<template>
  <v-dialog v-model="dialog" max-width="600" persistent round>
    <v-card v-if="service">
      <v-card-title>
        Details service [ {{ service.name }} ]
        <v-spacer />
        <v-btn icon class="v-btn--active" color="primary" @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text class="pa-6" >
        <div v-html="service.description">

        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
      
<script>
export default {
  data() {
    return {
      dialog: false,
      service: null,
    };
  },
  methods: {
    open(service) {
      this.service = service;
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
  },
};
</script>