<template>
  <v-container fluid>
    <div class="main">
      <v-row>
        <v-col cols="12" lg="3">
          <v-card class="rounded-lg" elevation="0">
            <v-list>
              <v-list-item-group v-model="model" mandatory>
                <v-list-item
                  color="primary"
                  class="translateY nav-list"
                  active-class="border--primary"
                  v-for="(item, i) in items"
                  :key="i"
                  :value="item.value"
                >
                  <v-list-item-icon>
                    <v-icon color="primary" dense v-text="item.icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.text"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>

           
          </v-card>
          <v-btn @click="logout" large
          variant="outlined"
          block
                    text
                    color="gifty"
                    class="text-decoration-none mt-3"
                    >
                    <v-icon color="gifty" class="mr-2" dense >mdi-logout</v-icon> 
                    Déconnecter
                </v-btn>
        </v-col>
        <v-col cols="12" lg="9">
          <Statistiques v-show="model === 1" ref="statistiques" />
          <Settings v-show="model === 2" ref="settings" />
          <Payments v-show="model === 3" ref="payments" />
          
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import Statistiques from "./Statistiques.vue";
import Settings from "./Settings.vue";
import Payments from "./Payments.vue";

import { HTTP } from "@/http-common";

export default {
  components: { Statistiques, Settings, Payments },
  data() {
    return {
      items: [
        { text: "Statistiques", icon: "mdi-clipboard-text-outline", value: 1 },
        { text: "Paramètres", icon: "mdi-cog", value: 2 },
        { text: "Mes Paiements", icon: "mdi-currency-usd", value: 3 },
      ],
      model: 1,
    };
  },
  methods: {
    logout() {
      this.loading = true;
      HTTP.post("auth/logout")
        .then(() => {
          this.loading = false;
          this.$store.dispatch("user", "");
          this.$store.dispatch("isLoggedIn", false);
          this.$store.dispatch("stores", []);
          this.$router.go(0);
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
  },
  computed: {},
  mounted() {
    this.$refs.statistiques.fetchData();
  },
  watch: {
    model(val) {
      switch (val) {
        case 1:
          return this.$refs.statistiques.fetchData();
          
        case 3:
          return this.$refs.payments.fetchData();
      }
    },
  },
};
</script>