<template>
  <div>
    <v-row class="mt-0">
      <v-col cols="12" class="pt-0">
        <h2 class="primary--text">Mes Paiements</h2>
      </v-col>
    </v-row>

    <v-row v-if="isLoading">
      <v-col cols="12" lg="6" class="pt-0" v-for="i in 6" :key="i">
        <v-card class="rounded-lg" flat>
          <v-card-text>
            <v-skeleton-loader
              type="list-item-avatar"
            ></v-skeleton-loader>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="12">
        <v-row v-if="payments.data.length > 0">
          <v-col
            cols="12"
            lg="6"
            v-for="payment in payments.data"
            :key="payment.id"
          >
            <v-card class="rounded-lg shadow mb-0">
              <v-card-text class="d-flex align-center">
                <v-avatar>
                  <v-img
                    :src="getImageLink(payment.recuperator.image)"
                    v-if="payment.recuperator.image"
                  />
                  <v-img
                    src="https://demos.themeselection.com/materio-vuetify-vuejs-admin-template/demo-1/assets/avatar-3.3ef9169b.png"
                    v-else
                  />
                </v-avatar>
                <div class="ml-2">
                  <h4 class="my-0">{{ payment.recuperator.name }}</h4>
                  <p class="py-0 my-0 grey--text" style="font-size: 12px">
                    {{ payment.date }}
                  </p>
                </div>
                <v-spacer></v-spacer>

                <div class="ml-2 text-right">
                  <span class="success--text darken-2 text-right">
                    Payé:
                    {{ CurrencyFormatting(payment.paid_amount) }} DZD </span
                  ><br />
                  <span class="pink--text darken-2 text-right">
                    Total:
                    {{ CurrencyFormatting(payment.real_amount) }} DZD
                  </span>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-pagination
              total-visible="6"
              v-if="payments.total > filter.size"
              circle
              v-model="filter.page"
              :length="Math.ceil(payments.total / filter.size)"
              @input="fetchData"
            ></v-pagination>
          </v-col>
        </v-row>
        <v-card v-else class="text-center" elevation="0">
          <v-card-text tile class="text">
            <v-img
              :src="require('@/assets/database.svg')"
              width="200"
              style="margin: auto"
            ></v-img>
            <p>Aucun Résultat</p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { HTTP } from "@/http-common";
export default {
  components: {},
  data() {
    return {
      isLoading: true,
      payments: {},
      filter: {
        page: 1,
        size: 12,
      },
    };
  },

  methods: {
    fetchData() {
      this.isLoading = true;
      HTTP.get("/v1/gifty/payments", {
        params: {
          ...this.filter,
        },
      })
        .then((res) => {
          this.payments = res.data.data;
          console.log(this.payments);
          this.isLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
        });
    },
  },
};
</script>