<template>
  <v-row class="mt-4 mb-5">
    <v-col>
      <h2 class="primary--text">Cartes de Recharge</h2>
    </v-col>
    <v-col cols="12" v-if="cardsLoading">
      <carousel>
        <slide v-for="i in 5" :key="i">
          <v-skeleton-loader type="article" class="mr-3" />
        </slide>
      </carousel>
    </v-col>

    <carousel
      v-else
      style="width: 100%"
      :autoplay="true"
      :perPageCustom="[
        [100, 1],
        [200, 1],
        [300, 1],
        [500, 1],
        [768, 3],
        [900, 4],
        [1024, 4],
      ]"
      :navigationEnabled="false"
      :loop="true"
      :minSwipeDistance="15"
      paginationColor="#d60c56"
    >
      <slide v-for="card in cards" :key="card.id">
        <v-card
          class="rounded mb-3 mx-3"
          style="cursor: pointer; min-width: 90%"
          @click="consumeCard(card)"
        >
        <div class="ribbon ribbon-top-right" v-if="card.service.promotion_user">
          <span class="font-weight-bold text-capitalize">Promo: - {{ card.service.promotion_user.discount }} %</span>
        </div>
          <v-img :aspect-ratio="16 / 9" :src="getImageLink(card.image)" />
          <v-card-text class="font-weight-bold text-center text-no-wrap">
            {{ card.name }}
          </v-card-text>
        </v-card>
      </slide>
    </carousel>
    <FormCard ref="formCard" />
  </v-row>
</template>

<script>
import { HTTP } from "@/http-common";
import { Carousel, Slide } from "vue-carousel";

import FormCard from "../services/FormCard.vue";

export default {
  components: {
    Carousel,
    Slide,
    FormCard,
  },
  data() {
    return {
      cardsLoading: false,
      cards: [],
    };
  },

  methods: {
    getCards() {
      this.cardsLoading = true;
      HTTP.get("v1/mobile/cards")
        .then((res) => {
          this.cards = res.data;
          //console.log(this.cards);
          this.cardsLoading = false;
        })
        .catch(() => {
          this.cardsLoading = false;
        });
    },
    consumeCard(card) {
      this.$refs.formCard.openAmount(card.service, card.amount);
    },
  },
  mounted() {
    this.getCards();
  },
};
</script>

<style scoped>
.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
}

.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: "";
  display: block;
  border: 5px solid #09e087;
}
.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 7px 0;
  background-color: #19e912;
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.1);
  color: #fff;
  font: 500 16px/1 "Lato", sans-serif;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  text-align: center;
  z-index: 2;
}

/* top right*/
.ribbon-top-right {
  top: 0px;
  right: 0px;
}
.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}
.ribbon-top-right::before {
  top: 0;
  left: 22px;
}
.ribbon-top-right::after {
  bottom: 22px;
  right: 0;
}
.ribbon-top-right span {
  left: -27px;
  top: 29px;
  transform: rotate(45deg);
}
</style>