<template>
  <div>
    <v-card
      class="rounded-lg shadow"
      color="gifty"
      dark
      v-if="user.salepoint"
      style="height: 100% !important"
    >
      <v-card-text class="p-5 relative shadow">
        <v-btn icon to="/gifty/salepoint" class="edit_btn">
          <v-icon class="black--text">mdi-pencil</v-icon>
        </v-btn>
        <v-row>
          <v-col>
            <h2 class="mb-5 yellow--text lighten-3">
              {{ user.salepoint.pos_name }}
            </h2>
            <p class="white--text mb-1">
              <v-icon>mdi-phone</v-icon> {{ user.salepoint.owner_phone }}
            </p>
            <p class="white--text mb-1">
              <v-icon>mdi-folder</v-icon> {{ user.salepoint.type }}
            </p>
          </v-col>
          <v-col class="text-right" style="height calc(100% - 16px)!important">
            <v-icon size="80">mdi-store</v-icon>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col cols="8">
            <span class="white--text"></span>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- <v-card
      class="rounded-lg shadow"
      color="gifty"
      dark
      v-else
      style="height: 100% !important"
    >
      <v-card-text class="p-5 relative shadow">
        <v-row>
          <v-col>
            <h2 class="mb-5 yellow--text lighten-3 mt-3">
              Avez-vous un point de Vente ?
            </h2>
            <p class="white--text mb-1">
              <v-btn large class="rounded-lg primary" elevation="0" to="/gifty/salepoint">
                <v-icon>mdi-plus</v-icon> Créer un point de vente
              </v-btn>
            </p>
          </v-col>
          <v-col class="text-right" style="height calc(100% - 16px)!important">
            <v-icon size="80">mdi-store</v-icon>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col cols="8">
            <span class="white--text"></span>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card> -->
  </div>
</template>

<script>
export default {
  computed: {
    user() {
      return this.$store.getters.getUser.user;
    },
  },
};
</script>

<style scoped>
.edit_btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #fff;
  color: #444;
  z-index: 2;
  padding: 10px;
  border: 1px solid #666;
}
</style>