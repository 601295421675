<template>
  <div>
    <v-dialog
      transition="dialog-bottom-transition"
      max-width="800"
      v-model="dialog"
    >
      <v-card>
        <v-toolbar color="transparent" dark elevation="0">
          <v-spacer />
          <v-btn icon class="v-btn--active" color="primary" @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="p-4">
          <v-img
            :src="getImageLink(item.image)"
            :max-width="800"
            class="rounded-lg pointer"
            @click="openPubliciy(item)"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { HTTP } from "@/http-common";
export default {
  props: ["item"],
  data() {
    return {
      dialog: true,
    };
  },
  methods: {
    openPubliciy(pub) {
      this.track(pub, "click");
      window.open(pub.action, "_blank");
    },
    track(pub, action) {
      HTTP.post("/v1/mobile/publicities/track/" + pub.id, {
        action: action,
      })
        .then(() => {})
        .catch(() => {});
    },
    close() {
      this.track(this.item, "view");
      this.dialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>