<template>
  <div>
    <v-row v-if="loading">
      <v-col cols="12" md="6" v-for="index in 6" :key="index" class="rounded-lg">
        <v-skeleton-loader type="article" class="rounded-lg"/>
      </v-col>
    </v-row>
    
    <v-row v-if="!loading && notifications.data.length > 0">
      <v-col v-for="notification in notifications.data" :key="notification.id" cols="12" md="6" >
        <v-card class="rounded-lg" elevation="0" :disabled="notification.read_at">
          <v-card-title>
            {{ notification.title }}
          </v-card-title>
          <v-card-subtitle class="grey--text lighten-2">
            {{ notification.created_at }}
          </v-card-subtitle>
          <v-card-text class="font-weight-medium">
            {{ notification.message }}
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12">
        <v-pagination
                        total-visible="6"
                        v-if="notifications.total > filter.size"
                        circle
                        v-model="filter.page"
                        :length="Math.ceil(notifications.total / filter.size)"
                        @input="onPageChange"
                    ></v-pagination>

      </v-col>
    </v-row>

    <v-row v-if="!loading && notifications.data.length == 0" class="mt-5">
      <v-col cols="12">
        <h2 class="primary--text">
          <v-icon class="primary--text">mdi-bell-badge-outline</v-icon>
          Notifications
        </h2>
      </v-col>

      <v-col cols="12 text-center">
        <p class="text-center d-flex justify-center">
          <v-img
            :src="getImageLink('/images/notification.svg')"
            max-width="200"
          />
        </p>
        <h3>Aucune notification</h3>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { HTTP } from "@/http-common";
export default {
  data() {
    return {
      loading: true,
      notifications: {},
      filter: {
        page: 1,
        size: 10
      }
    };
  },

  methods: {
    fetchData() {
      this.loading = true;
      HTTP.get("/v1/gifty/notifications", {
        params: {
          ...this.filter
        }
        
      })
        .then((res) => {
          this.notifications = res.data.data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },

    onPageChange(){
      this.fetchData();
    }
  },
  mounted() {
    this.fetchData();
  },
};
</script>
