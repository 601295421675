<template>
    <div class="mt-4">
      <v-skeleton-loader v-if="typesLoading" type="article" height="50" class="text-center"/>
  
      <div v-else class="d-flex justify-start" style="overflow:auto">
        <v-btn
          elevation="0"
          rounded
          v-for="type in types"
          :color="selectedTypeId == type.id ? 'primary' : 'white'"
          class="mb-2 mr-2 py-5 text-none"
          :key="type.id"
          @click="select(type)"
          style="padding-left: 5px"
        >
          <v-avatar small style="width: 20px; margin-left: 0; padding: 0">
            <v-img
              :src="getImageLink(type.icon)"
              max-width="30"
              max-height="30"
            />
          </v-avatar>
          {{ type.name }}
        </v-btn>
      </div>
    </div>
  </template>
  
    <script>
  import { HTTP } from "@/http-common";
  
  export default {
    data() {
      return {
        selectedTypeId: null,
        selectedType: null,
        types: [],
        typesLoading: false,
      };
    },
    methods: {
      select(type) {
        if (this.selectedType == type) {
          this.selectedType = null;
          this.selectedTypeId = null;
          this.$emit("setType", null)
        } else {
          console.log(type);
          this.selectedType = type;
          this.selectedTypeId = type.id;
  
          this.$emit("setType", type)
        }
      },
      getTypes() {
        this.typesLoading = true;
  
        HTTP.get("/service-type")
          .then((res) => {
            this.types = res.data;
            this.typesLoading = false;
          })
          .catch((e) => {
            console.log(e);
            this.typesLoading = false;
          });
      },
    },
    mounted() {
      this.getTypes();
    },
  };
  </script>