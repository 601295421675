<template>
    <v-row>
          <!-- Balances -->
          <v-col cols="6" lg="3">
            <v-sheet :rounded="'lg'" style="height: 100%" class="text-center">
              <v-card-title class="align-center text-center">
                <v-avatar
                  class="elevation-3 text-center mt-2"
                  color="success"
                  size="38"
                  style="margin: auto"
                >
                  <v-icon color="white "> mdi-currency-usd</v-icon>
                </v-avatar>
              </v-card-title>

              <v-card-text class="align-start">
                <p class="font-weight-bold text-lg mb-1">Balance Totale</p>
                <p class="font-weight-black text-lg mb-1 title pink--text darken-2">
                  {{ CurrencyFormatting(account.all_balance) }}
                  DZD
                </p>
              </v-card-text>
            </v-sheet>
          </v-col>
          <!-- End Balances -->

          <!-- Sent Received -->
          <v-col cols="6" lg="3">
            <v-sheet :rounded="'lg'" style="height: 100%" class="text-center">
              <v-card-title class="align-center text-center">
                <v-avatar
                  class="elevation-3 text-center mt-2"
                  color="gifty"
                  size="38"
                  style="margin: auto"
                >
                  <v-icon color="white "> mdi-currency-usd</v-icon>
                </v-avatar>
              </v-card-title>

              <v-card-text class="align-start">
                <p class="font-weight-bold text-lg mb-1">Solde Reçu</p>
                <p class="font-weight-black text-lg mb-1 title  pink--text darken-2">
                  {{ CurrencyFormatting(account.total_received_refills) }}
                  DZD
                </p>
              </v-card-text>
            </v-sheet>
          </v-col>
          <!-- Sent Received -->

          <v-col cols="6" lg="3">
            <v-sheet :rounded="'lg'" style="height: 100%" class="text-center">
              <v-card-title class="align-center text-center">
                <v-avatar
                  class="elevation-3 text-center mt-2"
                  color="red"
                  size="38"
                  style="margin: auto"
                >
                  <v-icon color="white "> mdi-currency-usd</v-icon>
                </v-avatar>
              </v-card-title>

              <v-card-text class="align-start">
                <p class="font-weight-bold text-lg mb-1">Transactions Refusées</p>
                <p class="font-weight-black text-lg mb-1 title  pink--text darken-2">
                  {{ CurrencyFormatting(account.total_refused_refills) }}
                  DZD
                </p>
              </v-card-text>
            </v-sheet>
          </v-col>

          <v-col cols="6" lg="3">
            <v-sheet :rounded="'lg'" style="height: 100%" class="text-center">
              <v-card-title class="align-center text-center">
                <v-avatar
                  class="elevation-3 text-center mt-2"
                  color="blue"
                  size="38"
                  style="margin: auto"
                >
                  <v-icon color="white "> mdi-currency-usd</v-icon>
                </v-avatar>
              </v-card-title>

              <v-card-text class="align-start">
                <p class="font-weight-bold text-lg mb-1">Transactions En cours de verification</p>
                <p class="font-weight-black text-lg mb-1 title  pink--text darken-2">
                  {{ CurrencyFormatting(account.total_verification_refills) }}
                  DZD
                </p>
              </v-card-text>
            </v-sheet>
          </v-col>
          
          <!-- Vouchers -->
          <v-col cols="6" lg="3">
            <v-sheet :rounded="'lg'" style="height: 100%" class="text-center">
              <v-card-title class="align-center text-center">
                <v-avatar
                  class="elevation-3 text-center mt-2"
                  color="primary"
                  size="38"
                  style="margin: auto"
                >
                  <v-icon color="white "> mdi-currency-usd</v-icon>
                </v-avatar>
              </v-card-title>

              <v-card-text class="align-start">
                <p class="font-weight-bold text-lg mb-1">Bons d'achat</p>
                <p class="font-weight-black text-lg mb-1 title  pink--text darken-2" >
                  {{ CurrencyFormatting(account.total_vouchers) }}
                  DZD
                </p>

                <p class="font-weight-black text-lg mb-1  grey--text darken-2" >
                  Promo: {{ CurrencyFormatting(account.total_vouchers_promotions) }}
                  DZD
                </p>

                <p class="font-weight-black text-lg mb-1  pink--text darken-2" >
                  Frais: {{ CurrencyFormatting(account.total_vouchers_fees) }}
                  DZD
                </p>
              </v-card-text>
            </v-sheet>
          </v-col>
          <!-- End vouchers -->

          <!-- Flexies -->
          <v-col cols="6" lg="3">
            <v-sheet :rounded="'lg'" style="height: 100%" class="text-center">
              <v-card-title class="align-center text-center">
                <v-avatar
                  class="elevation-3 text-center mt-2"
                  color="gifty"
                  size="38"
                  style="margin: auto"
                >
                  <v-icon color="white "> mdi-currency-usd</v-icon>
                </v-avatar>
              </v-card-title>

              <v-card-text class="align-start">
                <p class="font-weight-bold text-lg mb-1">Flexy</p>
                <p class="font-weight-black text-lg mb-1 title  pink--text darken-2">
                  {{ CurrencyFormatting(account.total_flexies) }}
                  DZD
                </p>

                <p class="font-weight-black text-lg mb-1  grey--text darken-2" >
                  Promo: {{ CurrencyFormatting(account.total_flexies_promotions) }}
                  DZD
                </p>

                <p class="font-weight-black text-lg mb-1  pink--text darken-2" >
                  Frais: {{ CurrencyFormatting(account.total_flexies_fees) }}
                  DZD
                </p>
              </v-card-text>
            </v-sheet>
          </v-col>
          <!-- Flexies -->

          <!-- Cards -->
          <v-col cols="6" lg="3">
            <v-sheet :rounded="'lg'" style="height: 100%" class="text-center">
              <v-card-title class="align-center text-center">
                <v-avatar
                  class="elevation-3 text-center mt-2"
                  color="blue"
                  size="38"
                  style="margin: auto"
                >
                  <v-icon color="white "> mdi-currency-usd</v-icon>
                </v-avatar>
              </v-card-title>

              <v-card-text class="align-start">
                <p class="font-weight-bold text-lg mb-1">Cartes</p>
                <p class="font-weight-black text-lg mb-1 title  pink--text darken-2">
                  {{ CurrencyFormatting(account.total_cards) }}
                  DZD
                </p>

                <p class="font-weight-black text-lg mb-1  grey--text darken-2" >
                  Promo: {{ CurrencyFormatting(account.total_cards_promotions) }}
                  DZD
                </p>

                <p class="font-weight-black text-lg mb-1  pink--text darken-2" >
                  Frais: {{ CurrencyFormatting(account.total_cards_fees) }}
                  DZD
                </p>
              </v-card-text>
            </v-sheet>
          </v-col>
          <!-- Cards -->

          <!-- Sent Refills -->
          <v-col cols="6" lg="3">
            <v-sheet :rounded="'lg'" style="height: 100%" class="text-center">
              <v-card-title class="align-center text-center">
                <v-avatar
                  class="elevation-3 text-center mt-2"
                  color="gifty"
                  size="38"
                  style="margin: auto"
                >
                  <v-icon color="white "> mdi-currency-usd</v-icon>
                </v-avatar>
              </v-card-title>

              <v-card-text class="align-start">
                <p class="font-weight-bold text-lg mb-1">Solde Envoyé</p>
                <p class="font-weight-black text-lg mb-1 title  pink--text darken-2">
                  {{ CurrencyFormatting(account.total_sent_refills) }}
                  DZD
                </p>
              </v-card-text>
            </v-sheet>
          </v-col>
          <!-- Sent Refills -->

          
        </v-row>
</template>

<script>
export default {
    props: ['account']
}
</script>