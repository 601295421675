var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticClass:"mt-4 mb-5"},[_c('v-col',[_c('h2',{staticClass:"primary--text"},[_vm._v("Cartes de Recharge")])]),(_vm.cardsLoading)?_c('v-col',{attrs:{"cols":"12"}},[_c('carousel',_vm._l((5),function(i){return _c('slide',{key:i},[_c('v-skeleton-loader',{staticClass:"mr-3",attrs:{"type":"article"}})],1)}),1)],1):_c('carousel',{staticStyle:{"width":"100%"},attrs:{"autoplay":true,"perPageCustom":[
      [100, 1],
      [200, 1],
      [300, 1],
      [500, 1],
      [768, 3],
      [900, 4],
      [1024, 4],
    ],"navigationEnabled":false,"loop":true,"minSwipeDistance":15,"paginationColor":"#d60c56"}},_vm._l((_vm.cards),function(card){return _c('slide',{key:card.id},[_c('v-card',{staticClass:"rounded mb-3 mx-3",staticStyle:{"cursor":"pointer","min-width":"90%"},on:{"click":function($event){return _vm.consumeCard(card)}}},[(card.service.promotion_user)?_c('div',{staticClass:"ribbon ribbon-top-right"},[_c('span',{staticClass:"font-weight-bold text-capitalize"},[_vm._v("Promo: - "+_vm._s(card.service.promotion_user.discount)+" %")])]):_vm._e(),_c('v-img',{attrs:{"aspect-ratio":16 / 9,"src":_vm.getImageLink(card.image)}}),_c('v-card-text',{staticClass:"font-weight-bold text-center text-no-wrap"},[_vm._v(" "+_vm._s(card.name)+" ")])],1)],1)}),1),_c('FormCard',{ref:"formCard"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }