<template>
  <div>
    <v-row>
      <v-col cols="12">
        <h2 class="primary--text">
          <v-icon class="primary--text">mdi-history</v-icon> Historique
        </h2>
      </v-col>
    </v-row>

    <v-row v-if="transactionsLoading">
      <v-col cols="12" md="6" lg="4" v-for="i in 6" :key="i" class="mb-3">
        <v-skeleton-loader type="article"></v-skeleton-loader>
      </v-col>
    </v-row>

    <div v-else>
      <div v-if="transactions.data.length > 0">
        <v-row>
          <v-col
            cols="12"
            md="6"
            lg="4"
            v-for="transaction in transactions.data"
            :key="transaction.id"
          >
            <VoucherTransaction
              v-if="transaction.type === 'voucher'"
              :transaction="transaction"
            />
            <RefillTransaction
              v-if="
                transaction.type === 'sent_refill' ||
                transaction.type === 'received_refill'
              "
              :transaction="transaction"
              @acceptRefill="acceptRefill($event)"
            />
            <FlexyTransaction
              v-if="transaction.type === 'flexy'"
              :transaction="transaction"
            />
            <CardTransaction
              v-if="transaction.type === 'Idoom' || transaction.type === '4G'"
              :transaction="transaction"
            />
          </v-col>
        </v-row>

        <v-card class="rounded-lg" flat>
          <v-card-text>
            <div class="d-flex justify-center">
              <v-pagination
                total-visible="6"
                v-if="transactions.total > 15"
                circle
                v-model="filter.page"
                :length="Math.ceil(transactions.total / filter.size)"
                @input="getHistory"
              ></v-pagination>
            </div>
          </v-card-text>
        </v-card>
      </div>
      <div v-else class="text-center">
        <v-avatar tile size="200">
          <v-icon size="120" class="gifty--text lighten-2">mdi-swap-horizontal</v-icon>
        </v-avatar>
        <p>Aucun résultat  </p>
      </div>
    </div>

    <AcceptRefill ref="acceptRefill"/>
  </div>
</template>
  
  <script>
import VoucherTransaction from "./components/VoucherTransaction.vue";
import CardTransaction from "./components/CardTransaction.vue";
import RefillTransaction from "./components/RefillTransaction.vue";
import FlexyTransaction from "./components/FlexyTransaction.vue";
import AcceptRefill from "./services/AcceptRefill.vue";

import { HTTP } from "@/http-common";
export default {
  components: {
    VoucherTransaction,
    FlexyTransaction,
    CardTransaction,
    RefillTransaction,
    AcceptRefill
  },
  data() {
    return {
      transactions: [],
      transactionsLoading: true,
      filter: {
        size: 12,
        page: 1,
      },
    };
  },
  methods: {
    getHistory() {
      this.transactionsLoading = true;
      HTTP.get("/v1/gifty/history", {
        params: {
          ...this.filter,
        },
      })
        .then((res) => {
          this.transactions = res.data.data;
          this.transactionsLoading = false;
        })
        .catch((err) => {
          this.transactionsLoading = false;
          console.log(err);
        });
    },
    acceptRefill(refill){
      this.$refs.acceptRefill.open(refill);
    }
  },
  mounted() {
    this.getHistory();
  },
};
</script>