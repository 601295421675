<template>
  <div>
    <v-dialog v-model="dialog" max-width="500" persistent round>
      <v-card v-if="service">
        <v-card-title>
          Envoyer un solde
          <v-spacer />
          <v-btn icon class="v-btn--active" color="primary" @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text class="pa-6" style="overflow-y: scroll">
          <p class="text-center">
            <v-btn color="gifty" elevation="0" rounded class="white--text">
              Solde Global:
              {{
                CurrencyFormatting(
                  $store.state.user.user.account.balance +
                    $store.state.user.user.account.credit_balance
                )
              }}
              DZD
            </v-btn>
          </p>
          <p class="text-center">
            <v-btn color="gifty" elevation="0" rounded class="white--text mt-2">
              Solde Service: {{ CurrencyFormatting(service.user_balance) }} DZD
            </v-btn>
          </p>

          <p class="text-center">
            <v-btn
              text
              color="primary"
              @click="details"
              v-if="service.description"
            >
              <v-icon class="mr-3">mdi-image-text</v-icon>
              Details
            </v-btn>
          </p>
          <v-alert
            dense
            v-if="errors && errors.operation"
            color="red"
            dark
            icon="mdi-information-outline"
            border="left"
            prominent
          >
            {{ errors.operation[0] }}
          </v-alert>

          <v-alert color="red" icon="mdi-close" type="error" v-if="error">
            {{ error }}</v-alert
          >
          <div v-if="!confirm">
            <div v-if="choice == null">
              <v-row class="d-flex justify-space-between">
                <v-col cols="6">
                  <v-card
                    color="gifty"
                    flat
                    rounded
                    @click="setChoice('qrcode')"
                  >
                    <v-card-text class="text-center py-5">
                      <v-icon class="white--text" size="70"
                        >mdi-qrcode-scan</v-icon
                      >
                      <h4 class="white--text mt-3">Par QR Code</h4>
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-col cols="6">
                  <v-card
                    color="gifty"
                    flat
                    rounded
                    @click="setChoice('phone')"
                  >
                    <v-card-text class="text-center py-5">
                      <v-icon class="white--text" size="70">mdi-phone</v-icon>
                      <h4 class="white--text mt-3">Par Téléphone</h4>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </div>
            <v-row v-else>
              <v-col cols="12" class="mb-0" style="overflow: auto">
                <qrcode-stream
                  @decode="onDecode"
                  v-if="choice == 'qrcode' && !scanned"
                  class="mb-3"
                ></qrcode-stream>
                <QrcodeDropZone
                  @decode="onDecode"
                  v-if="choice == 'qrcode' && !scanned"
                  class="mb-3"
                />
                <QrcodeCapture
                  @decode="onDecode"
                  v-if="choice == 'qrcode' && !scanned"
                  class="mb-3"
                />

                <div v-if="scanLoading" class="d-flex justify-center">
                  <v-progress-circular
                    :width="3"
                    color="primary"
                    indeterminate
                  ></v-progress-circular>
                </div>
                <v-text-field
                  v-if="choice == 'phone'"
                  outlined
                  prepend-inner-icon="mdi-phone-outline"
                  type="number"
                  v-model="form.number"
                  label="Téléphone *"
                  :prefix="phoneCode + ''"
                  hide-details=""
                  :color="errors && errors.number ? 'red' : 'primary'"
                  :class="errors && errors.number ? 'redBorder mb-4' : 'mb-4'"
                  @input="
                    errors && errors.number ? (errors.number = '') : false
                  "
                  style="margin-left: 5px"
                >
                </v-text-field>
                <p class="red--text" v-if="choice == 'phone'">
                  {{ errors.number ? errors.number[0] : "" }}
                </p>
                <!-- End number -->

                <!--  Amount -->
                <!-- <v-text-field
                  outlined
                  prepend-inner-icon="mdi-currency-usd"
                  type="number"
                  v-model="form.amount"
                  label="Montant *"
                  hide-details=""
                  :color="errors && errors.amount ? 'red' : 'primary'"
                  :class="errors && errors.amount ? 'redBorder' : ''"
                  @input="
                    errors && errors.amount ? (errors.amount = '') : false
                  "
                  
                >
                </v-text-field>
                <p class="red--text">
                  {{ errors.amount ? errors.amount[0] : "" }}
                </p> -->
                <!-- End amount -->
              </v-col>

              <v-btn
                type="submit"
                @click="save()"
                depressed
                :loading="btnLoading"
                large
                block
                color="primary"
                class="rounded-lg mt-3 mb-5"
              >
                Continuer
              </v-btn>
            </v-row>
          </div>

          <v-row v-else>
            <v-col cols="12" class="mb-0" v-if="user">
              <p class="text-center">
                Nom : <span class="pink--text">{{ user.name }}</span>
              </p>

              <!--  Amount -->
              <v-text-field
                outlined
                prepend-inner-icon="mdi-currency-usd"
                type="number"
                v-model="form.amount"
                label="Montant *"
                hide-details=""
                :color="errors && errors.amount ? 'red' : 'primary'"
                :class="errors && errors.amount ? 'redBorder' : ''"
                @input="errors && errors.amount ? (errors.amount = '') : false"
                :min="1"
                :rules="$amountRules"
              >
              </v-text-field>
              <p class="red--text">
                {{ errors.amount ? errors.amount[0] : "" }}
              </p>
              <!-- End amount -->
            </v-col>

            <v-btn
              type="submit"
              @click="openCheckount"
              depressed
              :loading="btnLoading"
              large
              block
              color="primary"
              class="rounded-lg mt-3 mb-5"
            >
              Envoyer
            </v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <ServiceDescription ref="serviceDescription" />
    <Checkout ref="checkout" @confirm="confirmCheckout($event)" />
  </div>
</template>
    
    <script>
import { HTTP } from "@/http-common";
import ServiceDescription from "./ServiceDescription.vue";

import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from "vue-qrcode-reader";
import Checkout from "./Checkout.vue";
export default {
  components: {
    ServiceDescription,
    QrcodeStream,
    QrcodeDropZone,
    QrcodeCapture,
    Checkout,
  },
  data() {
    return {
      choice: null,
      dialog: false,
      service: null,

      confirm: false,
      user: null,
      scanned: false,
      scanLoading: false,

      // Loadings
      btnLoading: false,
      isCountriesLoading: false,

      // countries
      countries: [],

      phoneCode: "+213",
      error: "",
      errors: {},
      country_id: 1,

      form: {
        number: null,
        amount: null,
        sender_account_id: this.$store.state.user.user.account.id,
        recipient_account_id: null,
        service_id: null,
        paied: true,
        promo_code: "",
        refill_for_all_services: true,
      },

      with_promo_code: false,
    };
  },
  methods: {
    open(service) {
      this.service = service;
      this.dialog = true;

      this.getCountries();
    },

    openCheckount() {
      this.$refs.checkout.open(this.form, this.service);
    },

    close() {
      this.choice = null;
      this.dialog = false;
      this.error = null;
      this.errors = {};
      this.confirm = false;
      this.scanned = false;
    },
    getCountries() {
      this.isCountriesLoading = true;
      HTTP.get("v1/countries")
        .then((res) => {
          this.isCountriesLoading = false;
          this.countries = res.data.data;
        })
        .catch((err) => {
          this.isCountriesLoading = false;
          console.log(err);
        });
    },
    countryChanged() {
      var id = this.country_id;
      var country = this.countries.find((x) => x.id === id);

      this.phoneCode = "+" + country.phonecode;
    },
    save() {
      this.error = "";
      this.btnLoading = true;
      var phone = null;
      if (this.phoneCode && this.form.number) {
        phone = this.phoneCode + this.form.number;
      }

      console.log("phone Code : " + this.phoneCode);
      console.log("Number : " + this.form.number);

      this.errors = {};
      HTTP.post("v1/gifty/user_by_phone", {
        number: phone,
        amount: this.form.amount,
      })
        .then((response) => {
          this.btnLoading = false;
          if (response.data.success) {
            this.confirm = true;
            this.user = response.data.data;
            this.form.recipient_account_id = this.user.account.id;
            this.form.service_id = this.service.id;
          }
        })
        .catch((err) => {
          this.btnLoading = false;
          console.log(err);
          this.errors = err.response.data.errors;

          this.error = "Une erreur s'est produite veuillez corriger.";
        });
    },

    confirmSave() {
      this.btnLoading = true;

      var formData = {
        ...this.form,
        account_id: this.$store.state.user.user.account.id,
        number: this.phoneCode + this.form.number,
      };
      HTTP.post("v1/mobile/services/consume/13", formData)
        .then(() => {
          this.btnLoading = false;
          this.close();
          this.$successMessage = "opération effectuée avec succès";
          this.$store.dispatch("fetchAccount");
        })
        .catch((err) => {
          this.btnLoading = false;
          console.log(err);
          if (err.response && err.response.data.error == true) {
            this.errors = {
              operation: ["Erreur: impossible d'effectuer cette opération"],
            };
          } else {
            this.errors = err.response ? err.response.data.errors : {};
          }

          this.btnLoading = false;

          this.error = "Une erreur s'est produite veuillez corriger.";
        });
    },
    details() {
      this.$refs.serviceDescription.open(this.service);
    },

    setChoice(value) {
      this.choice = value;
    },

    onDecode(decodedString) {
      this.form.recipient_account_id = parseInt(decodedString);
      this.scanLoading = true;
      HTTP.post("v1/gifty/user_by_account_id", {
        account_id: this.form.recipient_account_id,
        amount: this.form.amount,
      })
        .then((response) => {
          this.btnLoading = false;
          this.scanLoading = false;
          if (response.data.success) {
            this.confirm = true;
            this.user = response.data.data;
            this.form.service_id = this.service.id;
            this.scanned = true;
          }
        })
        .catch((err) => {
          this.btnLoading = false;
          console.log(err);
          this.errors = err.response.data.errors;

          this.error = "Une erreur s'est produite veuillez corriger.";
          this.scanLoading = false;
        });
    },

    confirmCheckout(data) {
      if (data.payment_mode == "gifty") {
        this.confirmSave();
      }
      if (data.payment_mode == "cib") {
        this.pay(data.recaptcha_token);
      }
    },


    pay(recaptcha_token) {
      var formData = {
        ...this.form,
        account_id: this.$store.state.user.user.account.id,
        number: this.phoneCode + this.form.number,
        consume_service_id: 13,
        consume_type: "consume",
        recaptcha_token: recaptcha_token,
        payment_method: "cib",
        promo_code : this.form.promo_code
      };

      this.btnLoading = true;

      HTTP.post("v1/e-payments/store", formData)
        .then((res) => {
          this.btnLoading = false;
          window.location = res.data.data.checkout_url;
        })
        .catch((err) => {
          this.btnLoading = false;
          this.errors = err.response.data.errors;
          this.error = "Une erreur s'est produite, veuillez verifier le formulaire.";
        });
    },
  },
};
</script>